export const NotificationStatus = {
    success: "SUCCESS",
    error: "ERROR",
}

export const NotificationText = {
    eventAdd: "Event Added Successfully",
    eventUpdate: "Event Updated Successfully",
    imageAdded: "Images Added",
    placeAdded: "Place Address Added Sucessfully",
    defaultError: "Something Went Wrong!",
    removed: "Removed Sucessfully",
    profileUpdate: "Profile Update Sucessfully",
}