import React from 'react'
import { data } from '../../screens/data/djData'
import styled from 'styled-components'

const DjSection = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    gap: 40px;
    margin-bottom: 100px;
`
const DjDetails = styled.div`
    min-width: 180px;
    padding: 10px;
`
const DjImage = styled.div`
    width: 100%;
    border-radius: 14px;
`
const DjName = styled.div`
    font-weight: 600;
    font-size: 18px;
`
const DjTime = styled.div`
    font-weight: 500;
    font-size: 16px;
    color: #a6a6a6;
`
export const DjLineup = () => {
    return (
        <>
            <h2>Dj Lineup</h2>
            <DjSection>
                {data.map(({ name, image, time }) => (
                    <DjDetails>
                        <DjImage>
                            <img src={image} alt="" />
                        </DjImage>
                        <DjName>{name}</DjName>
                        <DjTime>{time}</DjTime>
                    </DjDetails>
                ))}
            </DjSection>
        </>
    )
}
