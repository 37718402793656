import React from 'react'
import {
    EventName,
    AttendingPeopleSection,
    AttendingPeople,
    DespSection,
    Description,
    DesSection,
    DateLocationSection,
    DateWapper,
    WapperIcon,
    WapperMain,
    WapperSub,
} from '../../screens/bookEventDetails.style'
import location from '../../assets/Icons/Location.svg'
import calender from '../../assets/Icons/Calendar.svg'
import doller from '../../assets/Icons/Doller.svg'
import { Avatar } from 'antd'
import '../../screens/Css/EventDetail.css'
import { ButtonAndSocialLinks } from './ButtonAndSocialLinks'

export const BookEventDetails = () => {
    return (
        <>
            <div className="row">
                <div className="col-8 ">
                    <EventName>Quiet clubbing show - Martin Garrix</EventName>
                    <AttendingPeopleSection>
                        <AttendingPeople>People are attending</AttendingPeople>
                        <Avatar.Group
                            maxCount={4}
                            maxStyle={{
                                color: '#fff',
                                backgroundColor: '#555',
                            }}
                        >
                            <Avatar src="https://joesch.moe/api/v1/random?key=2" />
                            <Avatar src="https://joesch.moe/api/v1/random?key=8" />
                            <Avatar src="https://joesch.moe/api/v1/random?key=8" />
                            <Avatar src="https://joesch.moe/api/v1/random?key=8" />
                            <Avatar src="https://joesch.moe/api/v1/random?key=8" />
                            <Avatar src="https://joesch.moe/api/v1/random?key=8" />
                            <Avatar src="https://joesch.moe/api/v1/random?key=8" />
                        </Avatar.Group>
                    </AttendingPeopleSection>
                    <DespSection>
                        <DesSection>
                            <input type="Checkbox" id="check" />
                            <Description>Description</Description>
                            <div className="content shown">
                                dummy text of the printing and typesetting
                                industry. Lorem Ipsum has been the industry's
                                standard dummy text ever since the 1500s, when
                                an unknown printer took a galley of type and
                                scrambled it to make a type.dummy text of the
                                printing and typesetting industry. Lorem Ipsum
                                has been the industry's standard dummy text ever
                                since the 1500s, when an unknown printer took a
                                galley of type and scrambled it to make a type.
                            </div>
                            <div className="content hide">
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua. Ut
                                enim ad minim veniam, quis nostrud exercitation
                                ullamco laboris nisi ut aliquip ex ea commodo
                                consequat. Duis aute irure dolor in
                                reprehenderit in voluptate velit esse cillum
                                dolore eu fugiat nulla pariatur. Excepteur sint
                                occaecat cupidatat non proident, sunt in culpa
                                qui officia deserunt mollit anim id est laborum.
                            </div>
                            <label className="see-more" htmlFor="check">
                                See More
                            </label>
                            <label className="see-less" htmlFor="check">
                                See Less
                            </label>
                        </DesSection>
                    </DespSection>
                    <DateLocationSection>
                        <div className="row">
                            <DateWapper className="col-4">
                                <WapperIcon>
                                    <img src={calender} alt="icon" />
                                </WapperIcon>
                                <div className="details">
                                    <WapperMain>22 December 2022</WapperMain>
                                    <WapperSub>
                                        Tuesday, 8:00pm - 12:00am
                                    </WapperSub>
                                </div>
                            </DateWapper>
                            <DateWapper className="col-4">
                                <WapperIcon>
                                    <img src={location} alt="icon" />
                                </WapperIcon>
                                <div className="details">
                                    <WapperMain>Location</WapperMain>
                                    <WapperSub>
                                        Tuesday, 8:00pm - 12:00am
                                    </WapperSub>
                                </div>
                            </DateWapper>
                            <DateWapper className="col-4">
                                <WapperIcon>
                                    <img src={doller} alt="icon" />
                                </WapperIcon>
                                <div className="details">
                                    <WapperMain>Free - $ 130.00</WapperMain>
                                    <WapperSub>
                                        Tuesday, 8:00pm - 12:00am
                                    </WapperSub>
                                </div>
                            </DateWapper>
                        </div>
                    </DateLocationSection>
                </div>
                <div className="col-4">
                    <div className="button-and-social-section">
                        <ButtonAndSocialLinks />
                    </div>
                </div>
            </div>
        </>
    )
}
