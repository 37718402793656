import { yupResolver } from '@hookform/resolvers/yup'
import { Form, Select, Tag } from 'antd'
import { DangerText, FlexRow } from 'components'
import { FormTextFormField, PrimaryButton, FormSelectionField, FormCheckBoxField, OutlinedButton, FormDivider, EventFileUploader, FilePreviewer, FormTextAreaFormField, FormSelectionWithAddField, FormDateField, FormTimeField } from 'elements'
import React, { useState, useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import styled from 'styled-components'
import { TicketTable } from './TicketTable'
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import { EventsService, MetaDataService } from 'utility'
import { useAuth } from 'context'

const TagSelector = styled(Form.Item)`
    height:128px;
    label {
        height:30px !important;
        display:block;
    }
    .ant-select-selector {
            height:115px;
            background: ${({ theme }) => theme.colors.secondary} !important;
            border: 0px !important;
            color: ${({ theme }) => theme.colors.white} !important;
            display: flow-root;
    }
    .ant-select-selection-placeholder {
        color: ${({ theme }) => theme.colors.gray} !important;
        top:13% !important;
    }
    .ant-select-selection-item {
        background: ${({ theme }) => theme.colors.primary} !important;
    }

    .ant-select-selection-overflow{
        max-height:115px;
        overflow:overlay;
    }
`

const EventSchema = yup.object().shape({
    EventName: yup.string().required('*Event Name is required'),
    ProviderId: yup.string().required('*Organizer is required'),
    CategoryId: yup.string().required('*Category is required'),
    PlaceId: yup.string().required('*Place is required'),
    WebSite: yup.string(),
    Email: yup.string().email(),
    ContactName: yup.string().required('*Contact Name is required'),
    ContactNumber: yup.string(),
    IsTicket: yup.boolean().default(false).oneOf([true, false], "You must select Event Type"),
    LanguageId: yup.string().required('*Language is required'),
    TimeZoneName: yup.string().required('*Language is required'),
    Description: yup.string(),
    EventStartDate: yup.string().required('*Event Start Date is required').required('*Event Start Date is required').nullable(true),
    EventEndDate: yup.string().required('*Event End Date is required').required('*Event End Date is required').nullable(true),
    EventStartTime: yup.string().typeError("*Event Start Time is required").required('*Event Start Time is required').nullable(true),
    EventEndTime: yup.string().typeError("*Event End Time is required").required('*Event End Time is required').nullable(true),
    FreeTicketAge: yup.string().when('$childFree', (childFree, schema) => childFree ? schema.required("*Year is Required") : schema.optional()),
    NGOId: yup.string().when('$IsNGO', (IsNGO, schema) => IsNGO ? schema.required("*NGO is Required") : schema.optional()),
})

const EventLayout = styled.div`
    padding: 1rem;
    background: ${({ theme }) => theme.colors.primary};
    box-shadow: 0px 5px 25px rgba(57, 57, 72, 10%);
    border-radius: 10px;

    .disabled {
        opacity:0.4;
    } 
    .absolute {
        position:absolute;
    }
`
export const AddEditEventForm = ({
    submitEvent,
    openPlaceModal,
    data,
    languageList,
    categoryList,
    imageCategoryList,
    placeList,
    timeZoneList,
    providerList,
    addProvider,
    defaultTcList,
    defaultFaqList,
    onPlaceSearch,
}) => {
    const [error, setError] = useState('');
    const [imagefiles, setImageFiles] = useState([]);
    const [eventTicketRate, setEventTicketRate] = useState([])
    const [code, setCode] = useState([])
    const [terms, setTerms] = useState([])
    const [faqs, setFaqs] = useState([])
    const [loading, setLoading] = useState(false)
    const [childFree, setChildFree] = useState(false)
    const [IsNGO, setIsNgo] = useState(false)
    const [imageModal, setImageModal] = useState(false)
    const { profile, CountryId } = useAuth()

    const navigate = useNavigate()
    const {
        control,
        handleSubmit, setValue,
        formState: { errors },
        watch, reset,
    } = useForm({
        resolver: yupResolver(EventSchema),
        context: { childFree, IsNGO },
        defaultValues: {
            EventName: "",
            FreeTicketAge: 2,
        },
    })

    const IsTicket = watch("IsTicket")
    const placeId = watch("PlaceId")
    const eventStartDate = watch('EventStartDate')
    const eventStartTime = watch('EventStartTime')
    const EOTD = watch('EOTD')
    const Tags = watch('Tags')

    const onTagChange = (data) => {
        let len = 0;
        data.map((item) => { len += item.length; return "" })
        if (len <= 500) { setValue("Tags", data) } else { data.pop(); setValue("Tags", data) }
    }

    const yearsList = useMemo(() => {
        const list = []
        for (let i = 1; i < 6; i += 1) {
            const label = `${i} year  `
            list.push({
                value: i,
                label,
            })
        }
        return list
    }, [])

    const UserProviderList = useMemo(() => {
        const list = [...providerList]
        if (!providerList?.find(item => item.ProviderId === profile?.Id) && profile?.Id) {
            list.push({ value: profile?.Id, label: profile?.FullName })
        }
        return list
    }, [providerList, profile])

    useEffect(() => {
        if (profile?.Id) {
            setValue("ProviderId", profile?.Id)
        }
    }, [UserProviderList])

    useEffect(() => {
        setValue("PlaceId", "")
    }, [CountryId])

    useEffect(() => {
        if (EOTD) {
            const end = new Date();
            end.setHours(23, 59, 59, 999);
            setValue("EventEndTime", dayjs(end))
        }
    }, [EOTD])

    useEffect(() => {
        if (!data?.ProviderEventId) {
            setValue("EventEndDate", eventStartDate)
        }
    }, [eventStartDate])

    useEffect(() => {
        setChildFree(true)
        reset({ FreeTicketAge: 2 })
    }, [])

    useEffect(() => {
        if (eventTicketRate.length) {
            setError("")
        }
    }, [eventTicketRate])

    useEffect(() => {
        if (data?.ProviderEventId) {
            const resetData = {
                ...data,
                EventStartDate: dayjs(data?.EventLocalStartTime || data.EventStartDateTime),
                EventStartTime: dayjs(data?.EventLocalStartTime || data.EventStartDateTime),
                EventEndDate: dayjs(data?.EventLocalEndTime || data.EventEndDateTime),
                EventEndTime: dayjs(data?.EventLocalEndTime || data.EventEndDateTime),
                IsTicket: !data.IsTicket,
                Tags: data?.Tags?.split(";"),
            }
            if (data?.Tags?.length === 0) {
                delete resetData.Tags
            }
            setChildFree(resetData.FreeTicketAge > 0)
            reset({ ...resetData });
            setFaqs(resetData.EventFAQ)
            setTerms(resetData.TC)
            setIsNgo(data.IsNGO)
            setEventTicketRate(data.TicketRate)
            setImageFiles([...data.EventImages])
        } else {
            reset({ EventName: "" })
            setImageFiles([])
            setEventTicketRate([])
            setFaqs(defaultFaqList)
            setTerms(defaultTcList)
        }
    }, [data, defaultTcList, defaultFaqList])

    const submit = async (formData) => {
        try {
            if (eventTicketRate.length === 0 && !IsTicket) {
                setError("Ticket Information is missing")
                return;
            }
            setLoading(true)
            const imageArray = imagefiles?.map(image => {
                return {
                    ...image,
                    Image: image.Image,
                    ImageCategoryId: image.ImageCategoryId,
                    IsActive: true,
                }
            })
            const startDate = (dayjs(formData.EventStartDate).format('YYYY-MM-DD') + " " + dayjs(formData.EventStartTime).format("HH:mm:ss"))
            const endDate = (dayjs(formData.EventEndDate).format('YYYY-MM-DD') + " " + dayjs(formData.EventEndTime).format("HH:mm:ss"))
            const formDataObj = {
                ...formData,
                EventTicketRate: eventTicketRate,
                EventImages: imageArray,
                EventStartDateTime: startDate,
                EventEndDateTime: endDate,
                CategoryId: formData.CategoryId,
                EventFAQ: faqs,
                TC: terms,
                IsTicket: !formData.IsTicket,
                Tags: Tags?.toString().replaceAll(",", ";") || "",
                IsNGO,
            }
            delete formDataObj.EventStartDate;
            delete formDataObj.EventEndDate;
            delete formDataObj.EventStartTime;
            delete formDataObj.EventEndTime;
            delete formDataObj.EOTD
            submitEvent(formDataObj)
            setChildFree(false)
            setEventTicketRate([])
            setError("")
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    const uploadFinish = async (data) => {
        setImageFiles([...imagefiles, ...data])
    }

    const cancelAddEdit = () => {
        navigate(-1)
    }

    const setTicket = (data) => {
        setEventTicketRate(data)
    }

    const openImageModal = () => {
        setImageModal(true);
    }

    const removeFile = async (fileName, id) => {
        let allFiltersFiles = [...imagefiles]
        allFiltersFiles = allFiltersFiles?.filter((item) => item.ImageId !== fileName)
        setImageFiles(allFiltersFiles)
        if (data?.ProviderEventId) {
            await EventsService.removeImage(id)
        }
    }

    const submitTerms = (data) => {
        setTerms(data)
    }

    const submitFaqs = (data) => {
        setFaqs(data)
    }

    const submitCode = (data) => {
        setCode(data)
    }

    const addEventProvider = async (data) => {
        const result = await MetaDataService.postEventProvider({ ProviderId: 0, ProviderName: data })
        addProvider(result.Result)
    }

    return (
        <EventLayout className="row mt-5 mb-5">
            <div>
                <Form>
                    <div className="d-flex align-items-center justify-content-between">
                        <h1>Add Event</h1>
                        <div className="d-flex">
                            <OutlinedButton
                                className="col mr-2  p-3 d-flex flex-row align-items-center border justify-content-center"
                                block
                                onClick={cancelAddEdit}
                            >
                                Cancel
                            </OutlinedButton>
                            <PrimaryButton
                                className="col p-3 d-flex flex-row align-items-center border justify-content-center"
                                block
                                onClick={handleSubmit(submit)}
                                loading={loading}
                            >
                                Save
                            </PrimaryButton>
                        </div>
                    </div>
                    <FormDivider className="mt-0" />
                    <div className="row">
                        <div className="col-md-9  col-sm-12">
                            <div className="row">
                                <h3>Basic Details</h3>
                                <Form.Item className="col-12 col-sm-12 col-md-6">
                                    <FormTextFormField
                                        control={control}
                                        label="Event Name"
                                        name="EventName"
                                        placeholder="Add Event Name"
                                        errors={errors?.EventName}
                                        defaultValue=""
                                    />
                                </Form.Item>
                                <Form.Item className="col-12 col-sm-12 col-md-6">
                                    <FormSelectionField
                                        control={control}
                                        label="Place"
                                        name="PlaceId"
                                        placeholder="Select Place"
                                        onSearch={onPlaceSearch}
                                        errors={errors?.PlaceId}
                                        defaultValue=""
                                        options={placeList}
                                        showSearch
                                    ><OutlinedButton className="ml-2" onClick={openPlaceModal}>Add Place</OutlinedButton>
                                    </FormSelectionField>
                                    <div className="mt-2">
                                        {placeList?.find((item => item.PlaceGroupId === placeId))?.FormattedAddress &&
                                            <Tag color="black">{placeList?.find((item => item.PlaceGroupId === placeId)).FormattedAddress}</Tag>}
                                    </div>
                                </Form.Item>
                                <Form.Item className="col-12 col-sm-12 col-md-6">
                                    <FormSelectionWithAddField
                                        control={control}
                                        label="Organizer"
                                        name="ProviderId"
                                        placeholder="Select Organizer"
                                        errors={errors?.ProviderId}
                                        defaultValue=""
                                        options={UserProviderList}
                                        addOption={addEventProvider}
                                        showSearch
                                    />
                                </Form.Item>
                                <Form.Item className="col-12 col-sm-12 col-md-6">
                                    <FormSelectionField
                                        control={control}
                                        label="Category"
                                        name="CategoryId"
                                        placeholder="Select Category"
                                        errors={errors?.CategoryId}
                                        defaultValue=""
                                        options={categoryList}
                                        showSearch
                                    />
                                </Form.Item>
                                <Form.Item className="col-12 col-sm-12 col-md-6">
                                    <FormSelectionField
                                        control={control}
                                        label="Language"
                                        name="LanguageId"
                                        placeholder="Select language"
                                        errors={errors?.LanguageId}
                                        defaultValue=""
                                        options={languageList}
                                        showSearch
                                    />
                                </Form.Item>
                                <TagSelector className="mb-4 col-12 col-sm-12 col-md-6 d-flex flex-column">
                                    <label>Tags</label>
                                    <Select
                                        name="Tags"
                                        onChange={onTagChange}
                                        value={Tags}
                                        placeholder="Select Tags"
                                        mode="tags"
                                        open={false}
                                        className="tag-selection"
                                        size="default"
                                        maxTagTextLength={70}
                                    />
                                </TagSelector>
                                <Form.Item className="col-12">
                                    <FormTextAreaFormField
                                        control={control}
                                        label="Description"
                                        name="Description"
                                        placeholder="Enter Description Here"
                                        errors={errors?.Description}
                                        defaultValue=""
                                        height={200}
                                    />
                                </Form.Item>
                                <FormDivider />
                                <h3>Date/Time Details</h3>
                                <Form.Item className="col-12 col-md-6 col-sm-12 col-md-6">
                                    <div className="row">
                                        <div className="col-6">
                                            <FormDateField
                                                control={control}
                                                label="Start Date"
                                                name="EventStartDate"
                                                errors={errors?.EventStartDate}
                                                defaultValue=""
                                            />
                                        </div>
                                        <div className="col-6">
                                            <FormTimeField
                                                control={control}
                                                label="Start Time"
                                                name="EventStartTime"
                                                errors={errors?.EventStartTime}
                                                defaultValue=""
                                            />
                                        </div>
                                    </div>
                                </Form.Item>
                                <Form.Item className="col-12 col-md-6 col-sm-12 col-md-6">
                                    <FormSelectionField
                                        control={control}
                                        label="Timezone"
                                        name="TimeZoneName"
                                        placeholder="Select TimeZone"
                                        errors={errors?.TimeZoneName}
                                        defaultValue=""
                                        options={timeZoneList}
                                        showSearch
                                    />
                                </Form.Item>
                                <Form.Item className="col-12 col-md-6 col-sm-12 col-md-6">
                                    <div className="row">
                                        <div className="col-6">
                                            <FormDateField
                                                control={control}
                                                label="End Date"
                                                name="EventEndDate"
                                                errors={errors?.EventEndDate}
                                                disabledDate={eventStartDate}
                                                defaultValue=""
                                            />
                                        </div>
                                        <div className="col-6">
                                            <label className="d-flex align-items-center"><span>End Time</span><span className="ml-2"><FormCheckBoxField
                                                control={control}
                                                label=""
                                                name="EOTD"
                                                errors={errors?.EOTD}
                                            >End Of The Day
                                            </FormCheckBoxField>
                                            </span>
                                            </label>
                                            <FormTimeField
                                                control={control}
                                                name="EventEndTime"
                                                errors={errors?.EventEndTime}
                                                defaultValue=""
                                            />
                                        </div>
                                    </div>
                                </Form.Item>
                                <FormDivider />
                                <h3>Contact Information</h3>
                                <Form.Item className="col-12 col-sm-12 col-md-6">
                                    <FormTextFormField
                                        control={control}
                                        label="Contact Name"
                                        name="ContactName"
                                        errors={errors?.ContactName}
                                        defaultValue=""
                                    />
                                </Form.Item>
                                <Form.Item className="col-12 col-sm-12 col-md-6">
                                    <FormTextFormField
                                        control={control}
                                        label="Contact Number"
                                        name="ContactNumber"
                                        placeholder="+00 00000 000000"
                                        errors={errors?.ContactNumber}
                                        defaultValue=""
                                    />
                                </Form.Item>
                                <Form.Item className="col-12 col-sm-12 col-md-6">
                                    <FormTextFormField
                                        control={control}
                                        label="Email"
                                        name="Email"
                                        placeholder="abc@gmail.com"
                                        errors={errors?.Email}
                                        defaultValue=""
                                    />
                                </Form.Item>
                                <Form.Item className="col-12 col-sm-12 col-md-6">
                                    <FormTextFormField
                                        control={control}
                                        label="Website"
                                        name="WebSite"
                                        placeholder="www.xyz.com"
                                        errors={errors?.WebSite}
                                        defaultValue=""
                                    />
                                </Form.Item>
                                <Form.Item className="col-12 col-sm-12 col-md-6">
                                    <div className="d-flex pr-0">
                                        <FormCheckBoxField
                                            control={control}
                                            label=""
                                            name="ChildFree"
                                            checked={childFree}
                                            defaultValue={childFree}
                                            onChange={(e) => {
                                                setChildFree(e.target.checked)
                                            }}
                                        >Kids Are Free Under </FormCheckBoxField>
                                        <div className="col">
                                            <FormSelectionField
                                                control={control}
                                                name="FreeTicketAge"
                                                placeholder="2 year   "
                                                defaultValue=""
                                                errors={errors?.FreeTicketAge}
                                                options={yearsList}
                                                mode="single"
                                            /></div>
                                    </div>
                                </Form.Item>
                                <Form.Item className="col-12 col-sm-12 col-md-6">
                                    <div className="d-flex">
                                        <FormCheckBoxField
                                            control={control}
                                            label=""
                                            name="IsNGO"
                                            checked={IsNGO}
                                            onChange={(e) => {
                                                setIsNgo(e.target.checked)
                                            }}
                                        >Is NGO ?
                                        </FormCheckBoxField>
                                        <div className="col">
                                            <FormTextFormField
                                                control={control}
                                                name="NGOId"
                                                placeholder="NGOId"
                                                defaultValue=""
                                                errors={errors?.NGOId}
                                                disabled={!IsNGO}
                                            /></div>
                                    </div>
                                </Form.Item>
                                <Form.Item>
                                    <FormCheckBoxField
                                        control={control}
                                        label=""
                                        name="IsTicket"
                                        errors={errors?.IsTicket}
                                    >No Ticket
                                    </FormCheckBoxField>
                                    {error && <DangerText>{error}</DangerText>}
                                </Form.Item>
                            </div></div>
                        <Form.Item className="col-md-3  col-sm-12 mt-md-5 pt-md-4">
                            {imageModal && <EventFileUploader
                                setError={setError}
                                onSubmit={uploadFinish}
                                imagefiles={imagefiles}
                                imageCategoryList={imageCategoryList}
                                open={imageModal}
                                onClose={() => setImageModal(false)}
                                eventData={data}
                            />}
                            <OutlinedButton className="col-12" onClick={openImageModal}> Upload Images </OutlinedButton>
                            <div className="mt-3"><FilePreviewer perRowItem={2} files={imagefiles || []} removeFile={removeFile} /></div>
                        </Form.Item>
                    </div>
                    <div>
                        <><FormDivider />
                            <TicketTable
                                setTicket={setTicket}
                                IsTicket={IsTicket}
                                data={eventTicketRate}
                                eventStartTime={eventStartTime}
                                eventStartDate={eventStartDate}
                                eventData={data}
                                submitTerms={submitTerms}
                                submitFaqs={submitFaqs}
                                submitCode={submitCode}
                                terms={terms}
                                faqs={faqs}
                                code={code}
                            />
                        </>
                    </div>
                    <FlexRow className="mt-3 justify-content-end">
                        <div className="col-md-1 col mx-2">
                            <OutlinedButton
                                className=" mr-2  p-3 d-flex flex-row align-items-center border justify-content-center"
                                block
                                onClick={cancelAddEdit}
                            >
                                Cancel
                            </OutlinedButton>
                        </div>
                        <div className="col-md-1 col">
                            <PrimaryButton
                                className=" p-3 d-flex flex-row align-items-center border justify-content-center"
                                block
                                onClick={handleSubmit(submit)}
                                loading={loading}
                            >
                                Save
                            </PrimaryButton></div>
                    </FlexRow>
                </Form>
            </div>
        </EventLayout>
    )
}