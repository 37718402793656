import React, { useState } from 'react'
import { Modal } from 'antd'
import '../../screens/Css/ButtonAndSocial.css'
import styled from 'styled-components'
import Like from '../../assets/Icons/heart-regular.svg'
import Instagram from '../../assets/Icons/Instagram.svg'
import Whatsapp from '../../assets/Icons/Whatsapp.svg'
import Links from '../../assets/Icons/Link.svg'
import Email from '../../assets/Icons/Email.svg'
import { BuyTicketModal } from 'components/BuyTicketModal'

const ButtonWapper = styled.div`
    width: 100%;
    padding: 20px 20px;
    margin: 20px 0;
    background: #242631;
    border: 1px solid rgba(166, 166, 166, 0.09);
    border-radius: 14px;
    display: flex;
    flex-direction: column;
    gap: 14px;
`
const AddFav = styled.div`
    padding: 15px;
    width: 100%;
    background: #191a22;
    border-radius: 12px;
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    cursor: pointer;
`
const LikeImg = styled.img`
    width: 20px;
    height: 20px;
    filter: invert(100%);
`
const BuyTicketBtn = styled.div`
    padding: 15px;
    width: 100%;
    background: #ff384e;
    border-radius: 12px;
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    cursor: pointer;
`
const TicketRange = styled.span`
  text-align: center;
    font-weight: 600;
    font-size: 16px;
    color: #a6a6a6;
`
const SocialLinks = styled.div`
    width: 100%;
    padding: 20px 20px;
    margin: 20px 0;
    background: #242631;
    border: 1px solid rgba(166, 166, 166, 0.09);
    border-radius: 14px;
    display: flex;
    flex-direction: column;
    gap: 14px;
`
const SocialIcon = styled.div`
    display: flex;
    align-items: center;
    gap: 15px;
`
export const ButtonAndSocialLinks = () => {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const showModal = () => {
        setIsModalOpen(true)
    }
    const handleOk = () => {
        setIsModalOpen(false)
    }
    const handleCancel = () => {
        setIsModalOpen(false)
    }

    return (
        <>
            <ButtonWapper>
                <AddFav>
                    <LikeImg src={Like} alt="like" />
                    <span>Add to Favourite</span>
                </AddFav>
                <BuyTicketBtn onClick={showModal}>
                    <span>Buy Ticket</span>
                </BuyTicketBtn>
                <TicketRange>Ticket range: Free - $150</TicketRange>
            </ButtonWapper>
            <SocialLinks>
                <div className="share-width">Share Width</div>
                <SocialIcon>
                    <a href="#" className="icon">
                        <img src={Instagram} alt="#" />
                    </a>
                    <a href="#" className="icon">
                        <img src={Whatsapp} alt="#" />
                    </a>
                    <a href="#" className="icon">
                        <img src={Links} alt="#" />
                    </a>
                    <a href="#" className="icon">
                        <img src={Email} alt="#" />
                    </a>
                </SocialIcon>
            </SocialLinks>

            <Modal
                title="Select tickets categories"
                centered
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                width={800}
                footer={null}
            >
                <BuyTicketModal />
            </Modal>
        </>
    )
}
