import React from 'react'
import AddIcon from '../assets/Icons/add.svg'
import styled from 'styled-components'
import MinusIcon from '../assets/Icons/minus.svg'
import { useNavigate } from 'react-router-dom'
import '../screens/Css/BuyTicketModal.css'
import { Routes } from 'utility'

const ModalContainer = styled.div`
width: 100%,
`
const ModalWarpper = styled.div`
    background: #242631;
    border-radius: 14px;
    padding: 13px 20px;
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`
const OuterBox = styled.div`
    display: flex;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 6px;
    padding: 5px;
    align-items: center;
    gap: 12px;
`
const Icon = styled.div`
    width: 34px;
    height: 34px;
    background: rgba(138, 138, 138, 0.3);
    border-radius: 4px;
    display: grid;
    place-items: center;
    cursor: pointer;
`
const AddTicketBtn = styled.div`
    padding: 6px 12px;
    background: #ff384e;
    border-radius: 6px;
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    cursor: pointer;
`
const TotalMain = styled.div`
    background: #242631;
    border-radius: 30px 30px 0px 0px;
    padding: 18px 20px;
`
const TotalFees = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
`
const Tag = styled.div`
    font-size: 18px;
    font-weight: 700;
    color: #ffffff;
`
const Amount = styled.div`
    font-weight: 500;
    font-size: 18px;
    color: #ffffff;
    opacity: 0.6;
`
const Amt = styled.span`
    margin-left: 10px;
`
const ServiceFees = styled.div`
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    border-bottom: 2px dashed #8a8a8a48;
`
const TotalAmount = styled.div`
    display: flex;
    margin-top: 10px;
    justify-content: space-between;
`
const NextBtn = styled.div`
    margin: 0 auto;
    margin-top: 20px;
    width: 70%;
    padding: 12px 12px;
    background: #ff384e;
    border-radius: 12px;
    text-align: center;
    font-weight: 600;
    font-size: 18px;
    text-align: center;
    cursor: pointer;
`
export const BuyTicketModal = ({ toggleModal }) => {
    const navigate = useNavigate()
    const menuClick = (url, auth) => {
        if (auth) {
            toggleModal(auth)
        } else if (url) {
            navigate(url)
        }
    }
    return (
        <ModalContainer>
            <div className="row">
                <div className="col-6">
                    <ModalWarpper>
                        <div className="item">
                            <div className="item-name">VVIP seats</div>
                            <div className="item-price">
                                $ <span>150</span>
                            </div>
                        </div>
                        <div className="qty-selector">
                            <OuterBox>
                                <Icon>
                                    <img src={MinusIcon} alt="" />
                                </Icon>
                                <span>01</span>
                                <Icon>
                                    <img src={AddIcon} alt="" />
                                </Icon>
                            </OuterBox>
                        </div>
                    </ModalWarpper>
                </div>
                <div className="col-6">
                    <ModalWarpper>
                        <div className="item">
                            <div className="item-name">Platinum seats</div>
                            <div className="item-price">
                                $ <span>60</span>
                            </div>
                        </div>
                        <div className="qty-selector">
                            <OuterBox>
                                <Icon>
                                    <img src={MinusIcon} alt="" />
                                </Icon>
                                <span>01</span>
                                <Icon>
                                    <img src={AddIcon} alt="" />
                                </Icon>
                            </OuterBox>
                        </div>
                    </ModalWarpper>
                </div>
                <div className="col-6">
                    <ModalWarpper>
                        <div className="item">
                            <div className="item-name">VIP seats</div>
                            <div className="item-price">
                                $ <span>120</span>
                            </div>
                        </div>
                        <AddTicketBtn>
                            <span>Add Ticket</span>
                        </AddTicketBtn>
                    </ModalWarpper>
                </div>
                <div className="col-6">
                    <ModalWarpper>
                        <div className="item">
                            <div className="item-name">Gold seats</div>
                            <div className="item-price">
                                $ <span>40</span>
                            </div>
                        </div>
                        <div className="qty-selector">
                            <OuterBox>
                                <Icon>
                                    <img src={MinusIcon} alt="" />
                                </Icon>
                                <span>01</span>
                                <Icon>
                                    <img src={AddIcon} alt="" />
                                </Icon>
                            </OuterBox>
                        </div>
                    </ModalWarpper>
                </div>
                <div className="col-6">
                    <ModalWarpper>
                        <div className="item">
                            <div className="item-name">Diamond seats</div>
                            <div className="item-price">
                                $ <span>60</span>
                            </div>
                        </div>
                        <div className="qty-selector">
                            <OuterBox>
                                <Icon>
                                    <img src={MinusIcon} alt="" />
                                </Icon>
                                <span>01</span>
                                <Icon>
                                    <img src={AddIcon} alt="" />
                                </Icon>
                            </OuterBox>
                        </div>
                    </ModalWarpper>
                </div>
                <div className="col-6">
                    <ModalWarpper>
                        <div className="item">
                            <div className="item-name">General seats</div>
                            <div className="item-price">
                                $ <span>150</span>
                            </div>
                        </div>
                        <div className="qty-selector">
                            <OuterBox>
                                <Icon>
                                    <img src={MinusIcon} alt="" />
                                </Icon>
                                <span>01</span>
                                <Icon>
                                    <img src={AddIcon} alt="" />
                                </Icon>
                            </OuterBox>
                        </div>
                    </ModalWarpper>
                </div>
            </div>
            <TotalMain>
                <TotalFees>
                    <Tag>Total fees</Tag>
                    <Amount>
                        2x<Amt>$280</Amt>
                    </Amount>
                </TotalFees>
                <ServiceFees>
                    <Tag>Service fees</Tag>
                    <Amount>
                        <span>$20</span>
                    </Amount>
                </ServiceFees>
                <TotalAmount>
                    <Tag>Total amount</Tag>
                    <Amount>
                        <span>$300</span>
                    </Amount>
                </TotalAmount>
                <NextBtn
                    onClick={() => menuClick(Routes.selectSeats)}
                >
                    <span>Next</span>
                </NextBtn>
            </TotalMain>
        </ModalContainer>
    )
}
