// import { OrderList } from 'components';
import React from 'react';
import styled from 'styled-components';

const OrderHead = styled.div`
.order-para{
  font-size: 16px;
  font-weight: 500;
}
.link {
  font-size: 16px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.black}
}
`
export const OrdersTab = () => {
    return (
        <OrderHead className="container-fluid">
          {/* <OrderList /> */}
          You have not any order
        </OrderHead>
    )
}