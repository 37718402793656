import { BaseService } from './base'
import { BrowserUtility } from '../browser-utility'
import { APIPath,FirebaseService,StorageConstant } from 'utility'
import { CommonUtility } from '../common'

class Auth {

    refreshToken = async () => {
       const token = await FirebaseService.auth.currentUser.getIdToken(true)
        return token
    }

    getRefreshToken() {
        const user = this.getUser();
        if (user) {
          return user.stsTokenManager.refreshToken
        }
        return '';
      }

    login(reqData) {
        const data = CommonUtility.objectToParams(reqData)
        return BaseService.post(`${APIPath.login}?${data}`,null)
    }

    storeToken(token) {
        BrowserUtility.save(StorageConstant.token,token)
    }

    storeUser(user) {
        BrowserUtility.saveObj(StorageConstant.user,user)
    }

    getToken() {
        return BrowserUtility.get(StorageConstant.token) || ''
    }

    getUser() {
        return BrowserUtility.getObj(StorageConstant.user);
    }

    logout() {
        BrowserUtility.remove(StorageConstant.token)
    }

    isAuthenticated() {
        const token = this.getToken()
        return !!token
    }

    register(reqData) {
        return BaseService.post(APIPath.register,reqData)
    }

    verifyCode(code) {
        return BaseService.post(`${APIPath.verifyCode}/${code}`,null)
    }

    updateUser(reqData) {
        return BaseService.post(APIPath.update,reqData)
    }

    updateUserAddress(reqData) {
        const data = CommonUtility.objectToParams(reqData)
        return BaseService.post(APIPath.addCutomerAddress,data)
    }
}

const AuthService = new Auth()
Object.freeze(AuthService)
export { AuthService }
