import { CalendarBlank, MapPin, Star } from 'phosphor-react';
import React from 'react'
import Slider from "react-slick";
import styled from 'styled-components';
import { ImageWithFallback } from './Common';
import { ImagePlaceHolder } from './Placeholder';

const settings = {
  className: "center",
  centerMode: true,
  infinite: true,
  slidesToShow: 1,
  speed: 500,
  dots: true,
  autoplaySpeed: 2000,
  swipeToSlide: true,
  focusOnSelect: false,
};

const contentStyle = {
  width: '100%',
  height: "350px",
};

const SliderWrapper = styled(Slider)`
    background: ${({ theme }) => theme.colors.secondary};
    .banner {
      outline:none;
    }
    .banner-name{
      position: absolute;
      font-size:18px;
      bottom: 9%;
      margin-left:0.5%;
      color: ${({ theme }) => theme.colors.white}
    }
    .slick-dots {
      bottom:0px;
    }
    overflow:hidden;
`
const SliderConatiner = styled.div`
  height:350px;
  position: relative;
`

export const CarouselSlider = ({ bannerList, loading }) => {
  return (
    <SliderConatiner>
      <SliderWrapper {...settings} className="">
        {bannerList?.map((item) =>
          <div className="banner" key={item.BannerId}>
            <ImageWithFallback src={item.Banner} alt="baner" style={contentStyle} />
            <div className="banner-name">
              <p className="mx-1">{item.BannerName}</p>
              <p className="d-flex">
                <span className="d-flex align-items-center">
                  <CalendarBlank size={22} className="mx-1" />Friday, 30 December, 6:00 PM
                </span>
                <span className="d-flex align-items-center mx-4">
                  <MapPin size={22} className="mx-1" />Rajpath Club
                </span>
                <span className="d-flex align-items-center mx-4">
                  <Star size={22} className="mx-1" />Business
                </span>
              </p>
            </div>
          </div>)}
      </SliderWrapper>
      {loading && <div style={contentStyle}><ImagePlaceHolder /></div>}
    </SliderConatiner>
  )
}