import React from 'react'
import styled from 'styled-components'

const MapOuter = styled.div`
    position: relative;
    text-align: center;
    height: 510px;
    width: 100%;
    margin-top: 50px;
    .gmap_canvas {
        overflow: hidden;
        background: none !important;
        height: 510px;
        width: 100%;
    }
    .gmap_canvas ifram {
        margin: 0 auto;
    }
`
export const Map = () => {
  return (
      <MapOuter>
          <div className="gmap_canvas">
              <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d337.1749767034725!2d-121.26368610131796!3d38.13864703975611!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x809aa01fee58a975%3A0x1341df7c06588c73!2s413%20E%20Lockeford%20St%2C%20Lodi%2C%20CA%2095240%2C%20USA!5e0!3m2!1sen!2sin!4v1681722760869!5m2!1sen!2sin"
                  width="600"
                  height="450"
                  title="my-fram"
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
              />
          </div>
      </MapOuter>
  )
}
