import { LoaderBar } from 'components';
import { GetCountryList } from 'hooks';
import { EmailVerificationModal } from 'page-components';
import React, { createContext, useState, useContext, useEffect, useMemo } from 'react'
import { Navigate } from 'react-router-dom';
import { AuthService, FirebaseService } from 'utility';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [openVerifiedPopup, setOpenVerifiedPopup] = useState(false);
    const [user, setUser] = useState(null);
    const [profile, setProfile] = useState(null);
    const [CountryId, setCountryId] = useState(1);
    const { data: countryList } = GetCountryList()
    const countryCode = useMemo(() => {
       return countryList?.find(item => item.CountryId.toString() === CountryId.toString())?.CountryShortName
    },[CountryId,countryList])

    const [loading, setLoading] = useState(true);

    const onAuthStateChanged = async (user) => {
        if (user) {
            setUser(user);
            const token = await user.getIdToken()
            await AuthService.storeToken(token)
            // await AuthService.storeUser(user)
            const result = await AuthService.login({ Token: token })
            const profile = result.Result
            setProfile({
                CellPhone: profile.CellPhone,
                CellPhoneCountryCode: profile.CellPhoneCountryCode,
                CountryCode: profile.CountryCode,
                CustomerUId: profile.CustomerUId,
                DefaultProfile: profile.DefaultProfile,
                Email: profile.Email,
                FirstName: profile.FirstName,
                FullName: profile.FullName,
                LastName: profile.LastName,
                ProfilePicture: profile.ProfilePicture,
                ProviderType: profile.ProviderType,
                SignInProvider: profile.SignInProvider,
                Id: profile.Id,
            })
            setIsAuthenticated(true);
            setOpenVerifiedPopup(!user.emailVerified)
        } else {
            setIsAuthenticated(false);
            setUser(null)
        }
        if (loading) setLoading(false);
    }

    useEffect(() => {
        const subscriber = FirebaseService.auth.onAuthStateChanged(onAuthStateChanged);
        return subscriber; // unsubscribe on unmount
    }, []);

    const logout = () => {
        AuthService.logout();
        FirebaseService.auth.signOut()
    }

    const updatePassword = (password) => {
    FirebaseService.updateUserPassword(password.confirmPassword)
    }

    const closeModal = () => {
        setOpenVerifiedPopup(false)
    }

    const contextData = useMemo(() => ({
        loading, user, profile, isAuthenticated, logout,updatePassword, setCountryId,countryCode,CountryId,
    }), [
        loading, user, profile, isAuthenticated, logout,updatePassword, setCountryId,countryCode,CountryId,
    ])

    return (
        <AuthContext.Provider value={contextData}>
            <>
                {children}
                <EmailVerificationModal
                    open={openVerifiedPopup}
                    closeModal={closeModal}
                />
            </>
        </AuthContext.Provider>
    )
}

export const useAuth = () => useContext(AuthContext)

export const ProtectRoute = ({
    redirectPath = '/',
    children,
}) => {
    const { isAuthenticated, loading } = useAuth();
    if (loading) {
        return <LoaderBar />
    }
    if (!isAuthenticated) {
        return <Navigate to={redirectPath} replace />
    }

    return (<>
        {children}
    </>)
}
