import { EventList, FlexRow, FlexRowWrap, ImageWithFallback, TextCenter } from "components";
import { RoundedCornerButton } from "elements";
import { ListPlus, Trash } from "phosphor-react";
import { useMemo, useState } from "react";
import styled from "styled-components";
import { theme } from "utility";
import { Collapse, Tag } from 'antd';

const { Panel } = Collapse;

const Filter = [{ title: "All", key: "all" }, { title: "Free", key: "free" }, { title: "Paid", key: "paid" }]

const FilterEvents = ({ filterChanged }) => {
    const [seletedFilter, setSelectedFilter] = useState('all')
    const onSelect = (key) => {
        setSelectedFilter(key)
        filterChanged(key)
    }
    return (
        <FlexRow className="justify-content-end filter">
            {Filter?.map(item => <RoundedCornerButton selected={seletedFilter === item.key} key={item.key} className="mx-1" onClick={() => onSelect(item.key)}>{item.title}</RoundedCornerButton>)}
        </FlexRow>
    )
}

export const PublicEventList = ({ events, goToDetail, fetchMoreData, hasMoreData, loading }) => {
    return (
        <div>
            <h2 className="">Events</h2>
            <EventList
                events={events}
                goToDetail={goToDetail}
                fetchMoreData={fetchMoreData}
                hasMoreData={hasMoreData}
                loading={loading}
            />
        </div>
    )
}

const HeaderWithFilter = styled.div`
@media (width < 768px) {
    text-align: center;
    .filter {
        justify-content: center !important;
    }
}
`
const EventTag = styled(Tag)`
    height:40px;
    display:flex;
    align-items:center;
    font-size:14px;
    margin-bottom:5px;
    color:white;

`

export const UpcomingEventList = ({ events, goToDetail, fetchMoreData, hasMoreData, loading }) => {
    const [filter, setFilter] = useState('all')
    const filteredEventList = useMemo(() => {
        let eventList = []
        switch (filter) {
            case "all": eventList = events; break
            case "free": eventList = events.filter(item => item.IsFreeEvent === true); break;
            case "paid": eventList = events.filter(item => item.IsFreeEvent === false); break;
            default: eventList = events;
        }
        return eventList
    }, [filter, events])
    return (
        <div>
            <HeaderWithFilter className="mt-4 row">
                <h2 className="col-md-6 col-sm-12">Upcoming Event List</h2>
                <h2 className="col"><FilterEvents filterChanged={(key) => setFilter(key)} /> </h2>
            </HeaderWithFilter>
            <EventList
                events={filteredEventList}
                goToDetail={goToDetail}
                fetchMoreData={fetchMoreData}
                loading={loading}
                // editMode
            />
            <TextCenter>
                <RoundedCornerButton className="col-3 my-2" color={theme.colors.gray} onClick={fetchMoreData} disabled={!hasMoreData}>
                    Load More
                </RoundedCornerButton>
            </TextCenter>
        </div>
    )
}

const Card = styled.div`
min-height: ${({ height }) => !height ? 144 : height}px;
background: ${({ theme }) => theme.colors.gray};
border: 1px solid #454A55;
cursor:pointer;
&:hover {
}
`
const TeamCard = styled.div`
    .ant-collapse-content {
        background: ${({ theme }) => theme.colors.background};
    }
    `

const CategoryCard = ({ item }) => {
    return (
        <Card className="d-flex flex-column align-items-center justify-content-center">
            <ImageWithFallback height="25" width="25" />
            <p>{item.label}</p>
        </Card>
    )
}

export const EventCategoryList = ({ categoryList }) => {
    return (
        <div className="">
            <h2>Events Categories</h2>
            <div className="row">{categoryList?.map(item => <div className="col-3" key={item.label}><CategoryCard item={item} /></div>)}</div>
        </div>
    )
}

const TeamMemberCard = ({ item, onAdd, onRemove, deAssignEvent }) => {
    return (
        <TeamCard>
            <Collapse>
                <Panel
                    header={<div className="d-flex align-items-center"><ImageWithFallback src={item.ProfilePicture} height="25" width="25" className="mr-2" />{item.MemberEmail}</div>}
                    key="1"
                    ghost
                    extra={<><ListPlus
                        size={22}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            onAdd(item)
                        }} /><Trash
                            className="ml-2"
                            size={22}
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                onRemove(item)
                            }} /></>}
                >
                    {item?.Events.length ? <>
                        <p className="mt-0">Assigned Events</p>
                        <FlexRowWrap> {item?.Events.map((item,i) => {
                            return <EventTag onClose={deAssignEvent} key={`${item.EventName}${i}`}>{item.EventName} </EventTag>
                        })}</FlexRowWrap>
                    </> : <p className="d-flex">No Assigned Events Found</p>}
                </Panel>
            </Collapse>
        </TeamCard>
    )
}

export const TeamList = ({ teamList, onAdd, onRemove, deAssignEvent }) => {
    return (
        <div className="">
            <div className="row">{teamList?.map(item => <div className="col-4 mb-2" key={item.label}><TeamMemberCard onAdd={onAdd} onRemove={onRemove} item={item} deAssignEvent={deAssignEvent} /></div>)}</div>
        </div>
    )
}