import axios from 'axios'
import { AuthService } from '../services'
import { APIPath } from '../constant'

const onSuccess = response => {
    return response.data
}

const onError = async error => {
    return Promise.reject({
        error: error?.response?.data?.error || error?.response?.data,
        status: error?.response?.status,
    })
}

// const refreshToken = async (error) => {
//     const originalRequest = error.config;
//     if (error?.response?.status === 403 && !originalRequest._retry) {
//         originalRequest._retry = true;
//         const refresh_token = AuthService.getRefreshToken();
//         if (refresh_token) {
//             try {
//                 const params = {
//                     refresh_token,
//                 };
//                 const result = await AuthService.refreshToken(params);
//                 AuthService.storeUser(result);
//                 originalRequest.headers.Authorization = `Bearer ${result.access_token}`;
//                 return originalRequest;
//             } catch (error) {
//                 window.location.href = '/'
//             }
//         } else {
//             window.location.href = '/'
//         }
//     }
//     return Promise.reject(error);
// }

const request = async (options, isSecure) => {
    const headers = {}

    if (isSecure) {
        const token = AuthService.getToken()
        headers.Authorization = `Bearer ${token}`
    }

    headers.app = 'GitKit'
    headers['Access-Control-Allow-Origin'] = '*'

    const client = axios.create({
        baseURL: APIPath.server,
        headers: { ...headers },
    })

    // client.interceptors.response.use((response) => response, async (error) => {
    //     const refreshReq = await refreshToken(error);
    //     return client(refreshReq);
    // });

    return client(options).then(onSuccess).catch(onError)
}

const uploadFiles = (url, data, headers) => {
    const token = AuthService.getToken()
    headers.Authorization = `Bearer ${token}`
    const urlPath = APIPath.server + url
    const client = axios({
        url: urlPath,
        method: 'POST',
        headers: { ...headers },
        data,
    })

    return client.then(onSuccess).catch(onError)
}

export class BaseService {
    static get(url, isSecure = true) {
        return request(
            {
                url,
                method: 'GET',
            },
            isSecure,
        )
    }

    static post(url, data, isSecure = true) {
        return request(
            {
                url,
                method: 'POST',
                data,
            },
            isSecure,
        )
    }

    static put(url, data, isSecure = true) {
        return request(
            {
                url,
                method: 'PUT',
                data,
            },
            isSecure,
        )
    }

    static moralisAPI(url) {
        const client = axios.create({
            baseURL: APIPath.moralisAPI,
        })
        return client({
            url,
            method: 'GET',
        })
            .then(onSuccess)
            .catch(onError)
    }

    static extenralAPICall(url) {
        const client = axios({
            url,
            method: 'GET',
            timeout: 1000 * 3,
        })
        return client.then(onSuccess).catch(onError)
    }

    static remove(url, isSecure = true) {
        return request(
            {
                url,
                method: 'DELETE',
            },
            isSecure,
        )
    }

    static upload = (url, data, header) => {
        const formData = new FormData();
        formData.append("", data);
        return uploadFiles(url, formData, header)
    }
}
