import React from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import styled from 'styled-components'
import { CustomScrollY } from './Common'
import { OrderListSkeleton } from './Placeholder'

const Container = styled(InfiniteScroll)`
    height: 58.7rem;
    overflow: auto;
    background-size: cover;
    }
    ${CustomScrollY};
`

export const OrderList = ({ orders = [], fetchMoreData, hasMoreData,loading }) => {
    return (
      <div className="row">
        <Container
          dataLength={orders?.length}
          next={fetchMoreData}
          hasMore={hasMoreData}
          className="row"
        >
          <>{orders?.map((item) => (
            <div
              className="col-12 "
              key={item.ProviderEventId}
            >""
            </div>))}
            </>
          <>{(orders?.length === 0 || loading) &&
            <OrderListSkeleton classStyle="" />}
          </>
        </Container>
      </div>
    )
  }
