import React, { useState } from 'react'
import { Modal } from 'antd'
import { useNavigate } from 'react-router-dom'
import { Routes } from 'utility'
import { ApplyCodeModal } from 'components/ApplyCodeModal'
import styled from 'styled-components'

const TicketSummaryMain = styled.div`
    padding-top: 50px;
    .heading-main {
        display: flex;
        margin: 0px 150px;
        margin-bottom: 20px;
        justify-content: space-between;
    }
    .heading {
        font-weight: 700;
        font-size: 22px;
    }
    .apply-code {
        background: #242631;
        border-radius: 6px;
        color: #ff384e;
        padding: 5px;
        text-align: center;
        font-weight: 600;
        font-size: 14px;
        cursor: pointer;
    }
    .ticket-details-main {
        background: #242631;
        border: 1.5px solid rgba(255, 255, 255, 0.1);
        border-radius: 14px;
        width: 77%;
        margin: 0 auto;
        padding: 20px;
    }
    .show-name {
        font-weight: 700;
        font-size: 20px;
        margin-bottom: 20px;
    }
    .show-details {
        display: flex;
        border-bottom: 2px solid #a6a6a64b;
    }
    .person-details,
    .location {
        margin-right: 50px;
    }
    .person-name,
    .location-name,
    .show-date {
        font-weight: 500;
        margin-bottom: 5px;
        font-size: 16px;
        color: #8a8a8a;
    }
    .person,
    .stree-name,
    .show-time {
        font-weight: 600;
        font-size: 18px;
        margin-bottom: 20px;
    }
    .seat-details-main {
        display: flex;
        margin-top: 20px;
        align-items: baseline;
    }
    .booked-seat-no {
        margin-right: 40px;
    }
    .head {
        font-weight: 500;
        font-size: 16px;
        color: #8a8a8a;
        margin-bottom: 5px;
    }
    .seat-no,
    .type {
        font-weight: 600;
        font-size: 18px;
    }
    .ticket-total-main {
        background: #242631;
        border-radius: 14px;
        width: 77%;
        margin: 20px auto;
        padding: 40px;
    }
    .total-fees {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
    }
    .tag {
        font-size: 18px;
        font-weight: 700;
        color: #ffffff;
    }
    .amount {
        font-weight: 500;
        font-size: 18px;
        color: #ffffff;
        opacity: 0.6;
    }
    .amount span {
        margin-left: 10px;
    }
    .service-fees {
        display: flex;
        justify-content: space-between;
        padding-bottom: 10px;
        border-bottom: 2px dashed #8a8a8a48;
    }
    .total-amount {
        display: flex;
        margin-top: 10px;
        justify-content: space-between;
    }
    .next-btn {
        margin: 0 auto;
        margin-top: 20px;
        width: 70%;
        padding: 12px 12px;
        background: #ff384e;
        border-radius: 12px;
        text-align: center;
        font-weight: 600;
        font-size: 18px;
        text-align: center;
        cursor: pointer;
    }
    .apply-code-modal {
        padding: 20px;
    }
`
export const TicketSummary = ({ toggleModal }) => {
    const navigate = useNavigate()
    const menuClick = (url, auth) => {
        if (auth) {
            toggleModal(auth)
        } else if (url) {
            navigate(url)
        }
    }
    const [isModalOpen, setIsModalOpen] = useState(false)
    const showModal = () => {
        setIsModalOpen(true)
    }
    const handleOk = () => {
        setIsModalOpen(false)
    }
    const handleCancel = () => {
        setIsModalOpen(false)
    }
    return (
        <>
            <TicketSummaryMain className="container">
                <div className="heading-main">
                    <div className="heading">Ticket summary</div>
                    <div className="apply-code" onClick={showModal}>
                        Apply code
                    </div>
                </div>
                <div className="ticket-details-main">
                    <div className="show-name">
                        Quiet clubbing show - Martin Garrix
                    </div>
                    <div className="show-details">
                        <div className="person-details">
                            <div className="person-name">Visitor name</div>
                            <div className="person">
                                Samantha smith + 3 others
                            </div>
                        </div>
                        <div className="location">
                            <div className="location-name">Location</div>
                            <div className="stree-name">
                                Thornridge cir, sgiloh, hawaii
                            </div>
                        </div>
                        <div className="day-deatils">
                            <div className="show-date">Date & Time</div>
                            <div className="show-time">
                                22 Dec 2022, 10.00pm
                            </div>
                        </div>
                    </div>
                    <div className="seat-details-main">
                        <div className="booked-seat-no">
                            <div className="head">Booked seat no</div>
                            <div className="seat-no">1, 2, 3, 4</div>
                            <div className="seat-no">4,5</div>
                        </div>
                        <div className="ticket-type">
                            <div className="head">Ticket type</div>
                            <div className="type">V.I.P</div>
                            <div className="type">V.I.I.P</div>
                        </div>
                    </div>
                </div>
                <div className="ticket-total-main">
                    <div className="total-fees">
                        <div className="tag">Total fees</div>
                        <div className="amount">
                            2x<span>$280</span>
                        </div>
                    </div>
                    <div className="service-fees">
                        <div className="tag">Service fees</div>
                        <div className="amount">
                            <span>$20</span>
                        </div>
                    </div>
                    <div className="total-amount">
                        <div className="tag">Total amount</div>
                        <div className="amount">
                            <span>$300</span>
                        </div>
                    </div>
                    <div
                        className="next-btn"
                        onClick={() => menuClick(Routes.finalTicketSummary)}
                    >
                        <span>Next</span>
                    </div>
                </div>
            </TicketSummaryMain>
            <Modal
                title="Apply coupon code"
                centered
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                width={700}
                footer={null}
                className="apply-code-modal"
            >
                <ApplyCodeModal />
            </Modal>
        </>
    )
}
