import React from 'react'
import Logo from '../assets/Icons/Logo.svg'
import { useNavigate } from 'react-router-dom'
import { Routes } from 'utility'
import { VvipSeats } from 'page-components/selectSeats/vvipSeats'
import Close from '../assets/Icons/Close.svg'
import styled from 'styled-components'

const SelectSeatsMain = styled.div`
    padding-top: 50px;
    .show-name-main {
        background: #242631;
        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 14px;
        padding: 20px;
        display: flex;
        justify-content: space-between;
        width: 1000px;
        margin: 0 auto;
        margin-bottom: 20px;
    }
    .show-name {
        font-size: 22px;
        font-weight: 700;
    }
    .date-location {
        display: flex !important;
        font-size: 16px;
        margin-top: 20px;
        align-items: baseline;
    }
    .date-time {
        margin-right: 25px;
    }
    .time {
        font-size: 14px;
        color: #a6a6a6;
        font-weight: 500;
    }
    .address {
        font-size: 14px;
        color: #a6a6a6;
        font-weight: 500;
    }
    .seat-arragment-main {
        margin-left: 150px;
        margin-right: 150px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .original-arragement {
        background: #242631;
        width: fit-content;
        border-radius: 8px;
        padding: 15px;
        text-align: center;
        font-size: 16px;
        font-weight: 600;
        color: #ff384fd4;
    }
    .seats-selection-main {
        display: flex;
        gap: 20px;
    }
    .seats-selection-btn {
        display: flex;
        align-items: center;
    }
    .available-btn {
        width: 34px;
        height: 34px;
        background: #242631;
        border-radius: 8px;
        margin-right: 10px;
    }
    .your-btn {
        width: 34px;
        height: 34px;
        background: #ff384e;
        border-radius: 8px;
        margin-right: 10px;
    }
    .taken-btn {
        background: #1f293d;
        border-radius: 8px;
        width: 34px;
        height: 34px;
        margin-right: 10px;
        display: grid;
        place-items: center;
    }
    .book-now-main {
        background: #242631;
        box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.2);
        border-radius: 20px 20px 0px 0px;
        width: 85%;
        margin: 10px auto;
        margin-bottom: 0;
        padding: 20px;
    }
    .total-booking {
        text-align: center;
        font-weight: 600;
        font-size: 16px;
        margin-bottom: 10px;
    }
    .total-booking span {
        margin-left: 10px;
    }
    .book-ticket {
        margin: 0 auto;
        padding: 10px 6px;
        background: #ff384e;
        border-radius: 12px;
        width: 400px;
        font-weight: 600;
        font-size: 16px;
        text-align: center;
        cursor: pointer;
    }
`
export const SelectSeats = ({ toggleModal }) => {
    const navigate = useNavigate()
    const menuClick = (url, auth) => {
        if (auth) {
            toggleModal(auth)
        } else if (url) {
            navigate(url)
        }
    }
    return (
        <SelectSeatsMain className="container">
            <div className="show-name-main">
                <div className="show-name">
                    Quiet clubbing show - Martin Garrix
                    <div className="date-location">
                        <div className="date-time">
                            <div className="date">22 December 2022</div>
                            <div className="time">
                                Tuesday, 8:00pm - 12:00am
                            </div>
                        </div>
                        <div className="location">
                            <div className="place">Location</div>
                            <div className="address">
                                Thornrige cir. Shilosh , hawaii 81063
                            </div>
                        </div>
                    </div>
                </div>
                <div className="logo-icon">
                    <img src={Logo} alt="" />
                </div>
            </div>
            <div className="seat-arragment-main">
                <div className="original-arragement">
                    Original seat arrangement will defer from this
                </div>
                <div className="seats-selection-main">
                    <div className="seats-selection-btn">
                        <div className="available-btn" />
                        <div className="text">Available</div>
                    </div>
                    <div className="seats-selection-btn">
                        <div className="your-btn" />
                        <div className="text">Your seats</div>
                    </div>
                    <div className="seats-selection-btn">
                        <div className="taken-btn">
                            <img src={Close} alt="" />
                        </div>
                        <div className="text">Taken</div>
                    </div>
                </div>
            </div>
            <VvipSeats />
            <div className="book-now-main">
                <div className="total-booking">
                    Total seat: 35 <span> Total pay amount: $40</span>
                </div>
                <div
                    className="book-ticket"
                    onClick={() => menuClick(Routes.ticketSummary)}
                >
                    <span>Add Ticket</span>
                </div>
            </div>
        </SelectSeatsMain>
    )
}
