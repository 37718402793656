import React from 'react'
import { CarouselSlider } from 'components'
import { GetEventBannerList, GetUpcomingEventList } from 'hooks'
import { EventCategoryList, UpcomingEventList } from 'page-components'
import { useNavigate } from 'react-router-dom'
import { CommanContainer } from 'elements'

export const Home = () => {
  const { data: events, loading, fetchMoreData, hasMore } = GetUpcomingEventList();
  const { data: bannerList, loading: bannerLoading } = GetEventBannerList();
  const navigate = useNavigate()
  // const { data: categoryList } = GetCategoryList()
  const cateList = [{ label: "Music" }, { label: "Drama" }, { label: "Movie" }, { label: "Party" }]

  const goToDetail = (id) => {
    navigate(`/events/${id}`)
  }
  return (
    <>
      <CarouselSlider bannerList={bannerList} loading={bannerLoading} />
      <CommanContainer className="container">
        <UpcomingEventList
          events={events}
          loading={loading}
          goToDetail={goToDetail}
          fetchMoreData={fetchMoreData}
          hasMoreData={hasMore}
        />
        <div className="mb-4">
          <EventCategoryList categoryList={cateList} />
        </div>
      </CommanContainer>
    </>
  )
}
