import DjImage1 from '../../assets/Yellow-claw.png'
import DjImage2 from '../../assets/Marting.png'
import DjImage3 from '../../assets/David.png'
import DjImage4 from '../../assets/LOrem.png'

export const data = [
    {
        image: DjImage1,
        name: 'Yellow claw',
        time: '8.30 -9.30',
    },
    {
        image: DjImage2,
        name: 'Martin garrix',
        time: '8.30 -9.30',
    },
    {
        image: DjImage3,
        name: 'David guetta',
        time: '8.30 -9.30',
    },
    {
        image: DjImage4,
        name: 'Matrix D',
        time: '8.30 -9.30',
    },
    {
        image: DjImage1,
        name: 'Yellow claw',
        time: '8.30 -9.30',
    },
    {
        image: DjImage2,
        name: 'Martin garrix',
        time: '8.30 -9.30',
    },
    {
        image: DjImage3,
        name: 'David guetta',
        time: '8.30 -9.30',
    },
    {
        image: DjImage4,
        name: 'Matrix D',
        time: '8.30 -9.30',
    },
    {
        image: DjImage1,
        name: 'Yellow claw',
        time: '8.30 -9.30',
    },
    {
        image: DjImage2,
        name: 'Martin garrix',
        time: '8.30 -9.30',
    },
    {
        image: DjImage3,
        name: 'David guetta',
        time: '8.30 -9.30',
    },
    {
        image: DjImage4,
        name: 'Matrix D',
        time: '8.30 -9.30',
    },
]