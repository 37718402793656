import { AuthProvider } from 'context'
import { GlobalStyles } from 'global'
import {
    BrowserRouter as Router,
    Outlet,
    Route,
    Routes,
} from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { FirebaseService, theme } from 'utility'
import {
    AddEditEventScreen,
    Home,
    EventsListScreen,
    EventsDetailScreen,
    ProfileScreen,
    BookEvents,
    SelectSeats,
    TicketSummary,
    FinalTicketSummary,
    Map,
} from 'screens'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify'
import { Layout } from 'layout'

FirebaseService.init()

function App() {
    const themeMode = theme
    return (
        <ThemeProvider theme={themeMode}>
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                theme="dark"
            />
            <AuthProvider>
                <GlobalStyles />
                <Router>
                    <Layout>
                        <Routes>
                            <Route path="/" element={<Outlet />}>
                                <Route index element={<Home />} />
                                <Route path="events" element={<Outlet />}>
                                    <Route
                                        index
                                        element={<EventsListScreen />}
                                    />
                                    <Route
                                        path=":id"
                                        element={<EventsDetailScreen />}
                                    />
                                    <Route
                                        path="create"
                                        element={<AddEditEventScreen />}
                                    />
                                    <Route
                                        path="edit/:id"
                                        element={<AddEditEventScreen />}
                                    />
                                </Route>
                                <Route path="profile" element={<Outlet />}>
                                    <Route index element={<ProfileScreen />} />
                                    <Route
                                        path=":id"
                                        element={<ProfileScreen />}
                                    />
                                </Route>
                                <Route
                                    path="bookEvents"
                                    element={<BookEvents />}
                                />
                                <Route
                                    path="selectSeats"
                                    element={<SelectSeats />}
                                />
                                <Route
                                    path="ticketSummary"
                                    element={<TicketSummary />}
                                />
                                <Route
                                    path="finalTicketSummary"
                                    element={<FinalTicketSummary />}
                                />
                                <Route
                                    path="map"
                                    element={<Map />}
                                />
                            </Route>
                        </Routes>
                    </Layout>
                </Router>
            </AuthProvider>
        </ThemeProvider>
    )
}

export default App
