const basePath = process.env.REACT_APP_API_PATH
const gtcCustomer = 'GTIKIT/GTCustomer'
export const APIPath = {
    server: basePath,

    // auth
    login: `Users/ValidateProviderToken`,
    register: `${gtcCustomer}`,
    verifyCode: `${gtcCustomer}/Verifycode`,
    update: `${gtcCustomer}/Update`,

    // Event
    getEvent: `GTEvent`,
    getEventDetails: `GTEvent`,
    getUpcomingEvent: `GTUpcomingPublicEvent`,
    getEventBanner: `GTEventBanner`,
    getPublicEvent: `GTPublicEvent`,
    addEvent: `${gtcCustomer}/GTEvents`,
    addPlaces: "GTIKIT/GTEventPlace/Addresses",
    addCutomerAddress: "GTIKIT/GTCustomer/Addresses",
    removeImage: "GTEvent/EventImage/Remove",
    removeFaq: "GTEvent/EventFAQ/Remove",
    removeTC: "GTEvent/EventTC/Remove",
    updateImage: "GTEvent/EventImage",
    updateFaq: "GTEvent/EventFAQ/Update",
    updateTC: "GTEvent/EventTC/Update",
    addFaq: "GTEvent/EventFAQ",
    addTc: "GTEvent/EventTC",
    updateTicket: "GTEvent/EventTicket/Update",
    addTicket: "GTEvent/EventTicket",
    removeTicket: "GTEvent/EventTicket/Remove",
    removeSeat: "GTEvent/Seat/Remove",
    defaultTc: "GTEvent/EventTCDefault",
    defaultFAQ: "GTEvent/EventFAQDefault",
    //
    getCountry: 'GTIKIT/Country',
    getEventProvider: 'GTEvent/EventProvider',
    posteventProvider: 'GTEvent/EventProvider',
    getLanguage: 'GTIKIT/GTCustomer/GetLanguage',
    getCategory: 'GTIKIT/EventCategory',
    getImageCategory: 'GTIKIT/GTEventImageCategory',
    getEventPlace: 'GTIKIT/GTEventPlace',
    getTimeZone: 'GTIKIT/StateTimeZone',
    getEentsForMember: 'Provider/Team/Member/Events',

    // team member
    getTeam: 'Provider/Team',
    inviteTeamMember: 'Provider/Team/InviteMember',
    assignMemberToEvents: 'Provider/Team/AllowEvents',
    assignTeamToEvent: 'Provider/Event/AllowMembers',
    removeMember: 'Provider/Team/Remove',
    getEventsForMember: 'Provider/Team/Member/Events',
    getRole: 'Provider/Team/role',

    // web user
    webuser: `Users`,
    role: `UserRole`,

    // Participants
    participation: `User/Participation`,

    // Logging
    logging: `ErrorLogging`,

    getSignedURL: `Events/Media`,

}
