export const Routes = {
    home: '/',
    addEvent: '/events/create',
    profile: '/profile',
    events: '/events',
    bookEvents: '/bookEvents',
    selectSeats: '/selectSeats',
    ticketSummary: '/ticketSummary',
    finalTicketSummary: '/finalTicketSummary',
    map: '/map',
}
