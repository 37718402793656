import { useState, useEffect } from 'react'
import { EventsService } from 'utility'

export const GetEventDetails = (id) => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true)
                const result = await EventsService.getEventDetails({ EventId: id, PageNumber: 0, PageSize: 1 });
                setData(result?.Result?.Event[0])
            } catch (error) {
                setError(error)
            } finally {
                setLoading(false)
            }
        }
        if (id) {
            fetchData()
        }
    }, [id])

    return {
        data, error, loading,
    }
}

export const GetEventList = () => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [hasMore, setHasMore] = useState(false)
    const [error, setError] = useState('')
    const [filter, setFilter] = useState({
        PageNumber: 0,
        PageSize: 5,
    })

    const fetchData = async () => {
        try {
            setLoading(true)
            const result = await EventsService.getEventList(filter);
            if (filter.PageSize === 0) {
                setData(result.Result.Event)
            } else {
                setData([...data, ...result.Result.Event])
            }
            setHasMore(result.Result.Event.length === filter.PageSize)
        } catch (error) {
            setError(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchData()
    }, [filter])

    useEffect(() => {
        setFilter({
            PageNumber: 0,
            PageSize: 5,
        })
    }, [])

    const filterChanged = (changeValues) => {
        setFilter({
            ...changeValues,
            PageNumber: 0,
            PageSize: 5,
        })
    }

    const fetchMoreData = () => {
        setFilter({
            ...filter,
            PageNumber: filter.PageNumber + 1,
            PageSize: 5,
        })
    }

    return {
        data, error, loading, fetchMoreData, filterChanged, hasMore,
    }
}

export const GetPublicEventList = () => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [hasMore, setHasMore] = useState(false)
    const [filter, setFilter] = useState({
        PageNumber: 0,
        PageSize: 10,
    })

    useEffect(() => {

        const fetchData = async () => {
            try {
                setLoading(true)
                const result = await EventsService.getPublicEventList(filter);
                if (filter.PageNumber === 0) {
                    setData(result.Result.Event)
                } else {
                    setData([...data, ...result.Result.Event])
                }
                setHasMore(result.Result.Event.length === filter.PageSize)
            } catch (error) {
                setError(error)
            } finally {
                setLoading(false)
            }
        }

        if (!loading) {
            fetchData()
        }

    }, [filter])

    const filterChanged = (changeValues) => {
        setFilter({
            ...changeValues,
            PageSize: 10,
            PageNumber: 0,
        })
    }

    const fetchMoreData = () => {
        setFilter({
            ...filter,
            PageNumber: filter.PageNumber + 1,
        })
    }

    return {
        data, error, loading, fetchMoreData, hasMore, filterChanged,
    }
}

export const GetEventBannerList = () => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    const fetchData = async () => {
        try {
            setLoading(true)
            const result = await EventsService.getEventBannerList();
            setData(result.Result)
        } catch (error) {
            setError(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchData()
    }, [])

    return {
        data, error, loading,
    }
}

export const GetUpcomingEventList = () => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [hasMore, setHasMore] = useState(false)
    const [filter, setFilter] = useState({
        PageNumber: 0,
        PageSize: 8,
    })

    useEffect(() => {

        const fetchData = async () => {
            try {
                setLoading(true)
                const result = await EventsService.getUpcomingEventList(filter);
                if (filter.PageSize === 0) {
                    setData(result.Result.Event)
                } else {
                    setData([...data, ...result.Result.Event])
                }
                setHasMore(result.Result.Event.length === filter.PageSize)
            } catch (error) {
                setError(error)
            } finally {
                setLoading(false)
            }
        }

        if (!loading) {
            fetchData()
        }
    }, [filter])

    const filterChanged = (changeValues) => {
        setFilter({
            ...changeValues,
            PageNumber: 0,
            PageSize: 8,
        })
    }

    const fetchMoreData = () => {
        setFilter({
            ...filter,
            PageNumber: filter.PageNumber + 1,
        })
    }

    return {
        data, error, loading, fetchMoreData, hasMore, filterChanged,
    }
}

export const GetMyEvent = () => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [hasMore, setHasMore] = useState(false)
    const [filter, setFilter] = useState({
        PageNumber: 0,
        PageSize: 5,
    })

    useEffect(() => {

        const fetchData = async () => {
            try {
                setLoading(true)
                const result = await EventsService.getEvent(filter);
                if (filter.PageNumber === 0) {
                    setData(result.Result.Event)
                } else {
                    setData([...data, ...result.Result.Event])
                }
                setHasMore(result.Result.Event.length === filter.PageSize)
            } catch (error) {
                setError(error)
            } finally {
                setLoading(false)
            }
        }

        if (!loading) {
            fetchData()
        }

    }, [filter])

    const filterChanged = (changeValues) => {
        setFilter({
            PageSize: 5,
            PageNumber: 0,
            ...changeValues,
        })
    }

    const fetchMoreData = () => {
        setFilter({
            ...filter,
            PageNumber: filter.PageNumber + 1,
        })
    }

    return {
        data, error, loading, fetchMoreData, hasMore, filterChanged,setData,
    }
}
