import styled from 'styled-components'

export const EventName = styled.h1`
    font-family: 'Manrope';
    font-weight: 800;
    font-size: 34px;
`
export const AttendingPeopleSection = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #a6a6a637;
`
export const AttendingPeople = styled.p`
    font-family: 'Manrope';
    font-weight: 500;
    font-size: 22px;
    color: #a6a6a6;
`
export const DespSection = styled.div`
    border-bottom: 2px solid #a6a6a637;
    margin: 20px 0;
`
export const Description = styled.div`
    font-weight: 600;
    font-size: 24px;
    color: #ffffff;
    margin-bottom: 10px;
`
export const DesSection = styled.div`
    margin-bottom: 2rem !important;
`
export const DateLocationSection = styled.div`
    border-bottom: 2px solid #a6a6a637;
    margin-bottom: 20px;
    padding: 10px;
`
export const DateWapper = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`
export const WapperIcon = styled.div`
    height: 50px;
    width: 50px;
    background: #242631;
    display: grid;
    place-content: center;
    border-radius: 50%;
`
export const WapperMain = styled.div`
    font-weight: 700;
    font-size: 16px;
`
export const WapperSub = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #a6a6a6;
`