import React from 'react';
import { Dropdown, Space, Typography } from 'antd';
import { CaretDown } from 'phosphor-react';
import { useAuth } from 'context';

export const CountryDropDown = ({ list }) => {
  const { setCountryId, CountryId } = useAuth()
  const items = list.map(item => { return { key: item.key,label: item.label,icon: <img height={30} width={30} src={item.FlagPath} alt="" /> } })

  return (
    <Dropdown
      menu={{
        items,
        selectable: true,
        selectedKeys: [CountryId.toString()],
        onSelect: ({ key }) => { setCountryId(key) },
      }}
      overlayClassName="asasas"
    >
      <Typography.Link>
        <Space>
        {items.find(item => item.key.toString() === CountryId.toString())?.icon}
        {items.find(item => item.key.toString() === CountryId.toString())?.label}
          <CaretDown size={22} color="#345893" />
        </Space>
      </Typography.Link>
    </Dropdown>
  )
}