import React from 'react'
import styled from 'styled-components'
import { CustomScrollY, FlexRowBetween, ImageWithFallback } from './Common'
import InfiniteScroll from 'react-infinite-scroll-component'
import { AppListSkeleton } from './Placeholder'
import { Link, useNavigate } from 'react-router-dom'
import { Checkbox, Tag } from 'antd'
import { DateUtility, theme, Routes } from 'utility'
import { OutlinedButton } from 'elements'
import { GearSix, Trash } from 'phosphor-react'

const CardLayout = styled.div`
    .disabled {
        pointer-events: none;
    }
    .hover-container {
        width: 100%;
        height: 100%;
        top: 0;
        position: absolute;
        .edit-delete {
            display: none;
            position: absolute;
            bottom: 1rem;
            right: 1rem;
        }
        &:hover {
            .edit-delete {
                display: block;
            }
            background: rgba(69, 74, 85, 0.3);
            border-bottom-left-radius: 4px !important;
            border-bottom-right-radius: 4px !important;
            box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03),
                0px 12px 16px -4px rgba(16, 24, 40, 0.08);
            .title {
                color: ${({ theme }) => theme.colors.white} !important;
            }
            .category {
                color: ${({ theme }) => theme.colors.white} !important;
            }
            .place {
                color: ${({ theme }) => theme.colors.white} !important;
            }
        }
    }
    .ant-card-body {
    }
    .img-container {
        position: relative;
        height: ${({ imgHeight }) => (!imgHeight ? 250 : imgHeight)}px;
        img {
            width: 100%;
            object-fit: cover;
            height: 100%;
            overflow: hidden;
            aspect-ratio: 2/1.5;
        }
    }
    .tag-freeEvent {
        position: absolute;
        top: 3%;
        right: 0%;
        padding-block: 1px;
        padding-inline: 8px;
        text-transform: uppercase;
        font-weight: 700;
    }
    .card-details {
    }
    .date {
        font-weight: 700;
        color: ${({ theme }) => theme.text.red};
        font-size: 14px;
    }
    .title {
        color: #b9bbbf !important;
        font-weight: 700;
        font-size: 18px;
    }
    .category {
        color: #a2a5aa !important;
        font-size: 14px;
    }
    .place {
        color: #a2a5aa !important;
        font-size: 14px;
    }
    position: relative;
    border: none !important;
`
const CardDetails = styled.div`
    bottom: 0;
    width: 100%;
    padding: 16px;
    font-weight: 500;
    font-size: 16px;
    color: ${({ theme }) => theme.colors.white} !important;
`

const Container = styled(InfiniteScroll)`
    height: 58.7rem;
    overflow: auto;
    background-size: cover;
    ${CustomScrollY};
`

export const EventItemCard = ({
    event,
    editMode = false,
    openDeleteModal,
    disableHover = false,
    imgHeight,
    checkBox,
    goToDetailPage,
    onSelectEvent,
    selected,
}) => {
    return (
        <div
            onClick={() => {
                if (onSelectEvent) {
                    onSelectEvent()
                } else {
                    goToDetailPage()
                }
            }}
        >
            <CardLayout imgHeight={imgHeight}>
                <div className="img-container">
                    <ImageWithFallback
                        className="img"
                        src="https://picsum.photos/500/200/?blur=2"
                        alt="event"
                    />
                    <Tag
                        color={
                            event.IsFreeEvent
                                ? theme.colors.success
                                : theme.colors.danger
                        }
                        className="tag-freeEvent"
                    >
                        {event.IsFreeEvent ? 'Free' : 'Paid'}
                    </Tag>
                </div>
                <CardDetails>
                    <FlexRowBetween className="date ">
                        {DateUtility.dateToString(event.EventStartDateTime)} -{' '}
                        {DateUtility.dateToString(event.EventEndDateTime)}
                    </FlexRowBetween>
                    <div className="title mt-2">{event.EventName}</div>
                    <div className=" category mt-2">{event.CategoryName}</div>
                    <div className="place mt-2">{event.PlaceName}</div>
                </CardDetails>
                <div
                    className={`hover-container ${disableHover && 'disabled'}`}
                >
                    {editMode && (
                        <div className="edit-delete">
                            <OutlinedButton
                                onClick={e => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    openDeleteModal(event)
                                }}
                            >
                                <Trash size={22} />
                            </OutlinedButton>
                            <Link to={`/events/edit/${event.ProviderEventId}`}>
                                <OutlinedButton className="ml-2">
                                    <GearSix size={22} />
                                </OutlinedButton>
                            </Link>
                        </div>
                    )}
                </div>
                {checkBox && (
                    <div className="d-flex justify-content-end">
                        <Checkbox checked={selected} onClick={onSelectEvent} />
                    </div>
                )}
            </CardLayout>
        </div>
    )
}

export const EventList = ({
    events,
    goToDetail,
    fetchMoreData,
    hasMoreData,
    loading,
    imgHeight,
    editMode,
    toggleModal,
}) => {
    const navigate = useNavigate()
    const menuClick = (url, auth) => {
        if (auth) {
            toggleModal(auth)
        } else if (url) {
            navigate(url)
        }
    }
    return (
        <div className="row">
            <Container
                dataLength={events?.length}
                next={fetchMoreData}
                hasMore={hasMoreData}
                className="row"
            >
                <>
                    {events?.map((item, i) => (
                        <div
                            className="col-12 col-md-4 col-sm-6 col-lg-4 mb-md-5"
                            key={item.ProviderEventId + i + item.EventName}
                            onClick={() => menuClick(Routes.bookEvents)}
                            pointer
                        >
                            <EventItemCard
                                event={item}
                                goToDetail={goToDetail}
                                imgHeight={imgHeight}
                                pointer
                                editMode={editMode}
                            />
                        </div>
                    ))}
                </>
                <>
                    {(events?.length === 0 || loading) && (
                        <AppListSkeleton classStyle="col-12 col-md-4 col-sm-6 col-lg-4 mb-5" />
                    )}
                </>
            </Container>
        </div>
    )
}
