import { Tabs } from 'antd'
import styled from 'styled-components'
import { ChangePasswordTab, OrdersTab, UserInfoTab,Team } from 'page-components'
import { useNavigate, useParams } from 'react-router-dom';
import { MyEvents } from 'page-components/profile/MyEvents';
import { useMemo } from 'react';

const TabsHeader = styled(Tabs)`
    .left {
        left:5px;
    }
    .ant-tabs-nav-wrap{
      font-size:16px;
      font-weight:600;
      border-bottom: 1px solid #6e6c6b;
    }
     .ant-tabs-ink-bar {
        background: ${({ theme }) => theme.colors.white} !important;
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${({ theme }) => theme.colors.white} !important;
}

.ant-tabs-tab:hover{
 color: ${({ theme }) => theme.colors.white} !important;
}
`
const TabsItems = [
    {
        label: `Profile`,
        key: `/profile`,
        children: <UserInfoTab />,
        path: "/profile",
    },
    {
        label: `Your Order`,
        key: `order`,
        children: <OrdersTab />,
        path: "/order",
    },
    {
        label: `My Events`,
        key: `my-events`,
        children: <MyEvents />,
        path: "/my-events",
    },
    {
        label: `My Team`,
        key: `my-team`,
        children: <Team />,
        path: "/my-team",
    },
    {
        label: `Change Password`,
        key: `change-password`,
        children: <ChangePasswordTab />,
        path: "/change-password",
    },

]

export function ProfileScreen({ children }) {
    const navigate = useNavigate();
    const { id } = useParams()
    const defaultSelected = useMemo(() => {
        if (id === 'profile') {
            return "/"
        }
        return id
    }, [id])

    return (
        <div className="container">
            {children}
            <TabsHeader
                className="left"
                defaultActiveKey={defaultSelected}
                onChange={(path) => {
                    navigate(`../${path}`)
                }}
                items={TabsItems}
            />
        </div>
    )
}
