import React from 'react'
import { Input, QRCode, Space, theme } from 'antd'
import { useNavigate } from 'react-router-dom'
import { Routes } from 'utility'
import styled from 'styled-components'

const FinalTicketMain = styled.div`
    .final-ticket-head {
        font-weight: 700;
        font-size: 22px;
        margin-top: 50px;
        margin-bottom: 20px;
        margin-left: 300px;
    }
    .summary-main {
        background: #242631;
        border-radius: 14px;
        width: 55%;
        margin: 0 auto;
        padding: 20px;
        margin-bottom: 20px;
    }
    .qr-code-main .ant-space {
        display: grid;
        place-content: center;
    }
    .ant-space .ant-space-item {
        display: grid;
        place-content: center;
    }
    .ant-space-item .ant-qrcode {
        background-color: transparent;
    }
    .ant-space-item .ant-input {
        display: none !important;
    }
    .ant-space-item .sub-text {
        font-weight: 400;
        font-size: 16px;
        color: #8a8a8a;
    }
    .ant-space-item  .text {
        font-weight: 600;
        font-size: 18px;
        text-align: center;
    }
    .line-main {
        display: flex;
        align-items: center;
        margin-top: 50px;
    }
    .line {
        border-bottom: 2px dashed #8a8a8a;
        width: 700px;
        position: relative;
        left: -37px;
    }
    .ticket-no {
        background: #191a22;
        border-radius: 6px;
        position: relative;
        left: 275px;
        width: 110px;
        padding: 5px;
        z-index: 1;
    }
    .shape-1 {
        position: relative;
        left: -30px;
    }
    .shape-2 {
        position: relative;
        right: -30px;
    }
    .shape-1,
    .shape-2 {
        width: 26px;
        height: 26px;
        background-color: #161d2a;
        border-radius: 50%;
    }
    .final-ticket-details-main {
        margin-top: 25px;
        padding-left: 100px;
    }
    .final-ticket {
        margin-bottom: 20px;
    }
    .full-name,
    .payment-method,
    .event-name,
    .locations-name,
    .date-time,
    .ticket-type {
        font-weight: 600;
        font-size: 16px;
        text-transform: uppercase;
        color: #8a8a8a;
        margin-bottom: 3px;
    }
    .name,
    .method,
    .event,
    .location,
    .time,
    .types {
        font-weight: 600;
        font-size: 18px;
    }
    .direction-btn {
        margin: 0 auto;
        width: 55%;
        margin-bottom: 100px;
        padding: 12px 12px;
        background: #ff384e;
        border-radius: 12px;
        text-align: center;
        font-weight: 600;
        font-size: 18px;
        text-align: center;
        cursor: pointer;
    }
`

export const FinalTicketSummary = ({ toggleModal }) => {
    const { useToken } = theme
    const navigate = useNavigate()
    const menuClick = (url, auth) => {
        if (auth) {
            toggleModal(auth)
        } else if (url) {
            navigate(url)
        }
    }
    const { token } = useToken()
    const [text, setText] = React.useState('')
    return (
        <FinalTicketMain className="container">
            <div className="final-ticket-head">Ticket summary</div>
            <div className="summary-main">
                <div className="row">
                    <div className="col-12 qr-code-main">
                        <Space className="qr-code" align="center">
                            <QRCode
                                value={text || '-'}
                                color={token.colorWhite}
                            />
                            <div className="text">QR Code</div>
                            <div className="sub-text">
                                Scan with the receptionist
                            </div>
                            <Input
                                placeholder="-"
                                maxLength={60}
                                value={text}
                                onChange={e => setText(e.target.value)}
                            />
                        </Space>
                        <div className="line-main">
                            <div className="shape-1" />
                            <div className="ticket-no">Ticket 1 of 3</div>
                            <div className="line" />
                            <div className="shape-2" />
                        </div>
                    </div>
                    <div className="col-12 final-ticket-details-main">
                        <div className="row final-ticket-details">
                            <div className="col-6 final-ticket">
                                <div className="full-name">Full name</div>
                                <div className="name">Samantha smith</div>
                            </div>
                            <div className="col-6 final-ticket">
                                <div className="payment-method">
                                    Payment via
                                </div>
                                <div className="method">Paypal</div>
                            </div>
                            <div className="col-6 final-ticket">
                                <div className="event-name">Event</div>
                                <div className="event">
                                    Quiet clubbing VIP heated
                                </div>
                            </div>
                            <div className="col-6 final-ticket">
                                <div className="locations-name">Location</div>
                                <div className="location">
                                    Thornridge Cir. Shiloh, Hawaii
                                </div>
                            </div>
                            <div className="col-6 final-ticket">
                                <div className="date-time">Date & Time</div>
                                <div className="time">22 Dec 2022 8:00 pm</div>
                            </div>
                            <div className="col-6 final-ticket">
                                <div className="ticket-type">Ticket type</div>
                                <div className="types">VIP</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="direction-btn"
                onClick={() => menuClick(Routes.map)}
            >
                <span>Go direction</span>
            </div>
        </FinalTicketMain>
    )
}
