import React from 'react'
import styled from 'styled-components'
import CoverImage from '../assets/Top-banner.png'
import { BookEventDetails } from 'page-components/bookEvents/BookEventDetails'
import { DjLineup } from 'page-components/bookEvents/DjLineup'

const CoversImage = styled.div`
  width: 100%;
`
const ImageTage = styled.img`
    width: 100% !important;
    height: 100%;
    margin: 60px 0;
`
export const BookEvents = () => {
    return (
        <>
            <div className="container ">
                <CoversImage>
                    <ImageTage src={CoverImage} alt="" />
                </CoversImage>
                <BookEventDetails />
                <DjLineup />
            </div>
        </>
    )
}
