import React, { useEffect, useMemo } from 'react'
import { AuthPopup, theme, Routes, CommonUtility } from 'utility'
import { useNavigate } from 'react-router-dom'
import { Dropdown, Form } from 'antd'
import styled from 'styled-components'
import { CountryDropDown, ImageContainer } from 'components'
import { List } from 'phosphor-react'
import { useAuth } from 'context'
import { RoundedCornerButton, FormSelectionField } from 'elements'
import { useForm } from 'react-hook-form';
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup';
import { GetCountryList } from 'hooks'

const CountrySchema = yup.object().shape({
    CountryId: yup.string(),

})

const ImageLogo = styled(ImageContainer)`
    height:40px;
`
const HeaderMain = styled.div`
    z-index:9999;
    .link{
        color:${({ theme }) => theme.colors.white}
    }

`
const ImageSider = styled(ImageLogo)`
    padding:0px;
`

const MenuTag = styled.div`
    line-height: normal;
    padding-right: 20px;
    cursor: pointer;
`

const HeaderLayout = styled.div`
    .sidebar {
        width:100%;
        height: inherit;
        padding : 0 15px;
    }
    
    height: inherit;

    .desktop {
        padding: 0 10px;
        height: inherit;
    }

    @media (width > 538px) {
        .sidebar {
            display:none !important;
        }
    }
    @media (width < 538px) { 
        .desktop {
            display:none !important;
        }
    }
`
const DropItem = styled.div`
    padding:5px 10px;
`

export function HeaderBar({ toggleModal, navigateToTop }) {

    const { isAuthenticated, logout, setCountryId, profile } = useAuth()
    const navigate = useNavigate()
    const { data: countryList } = GetCountryList()

    const {
        control, watch,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(CountrySchema),
        defaultValues: {
            CountryId: 1,
        },
    })

    const CountryId = watch("CountryId")

    useEffect(() => {
        setCountryId(CountryId)
    }, [CountryId])

    const logoutProfile = async () => {
        logout();
        navigate('/')
    }

    const menuClick = (url, auth) => {
        if (auth) {
            toggleModal(auth);
        } else if (url) {
            navigate(url)
        }
    }

    const menu = useMemo(() => [
        {
            label: <DropItem key="event" onClick={() => menuClick(Routes.events)}>Events</DropItem>,
            key: 'event',
        },
        {
            label: <DropItem key="addevent" onClick={() => menuClick(Routes.addEvent)}> Add Events</DropItem>,
            key: 'add-event',
        },
        {
            label: <DropItem key="profile" onClick={() => menuClick(Routes.profile)}>Profile</DropItem>,
            key: 'profile',
        },
        {
            label: <DropItem key="logout" onClick={() => logoutProfile()}>Logout</DropItem>,
            key: 'logout',
        },
    ]);

    const publicMenu = useMemo(() => [
        {
            label: <DropItem key="event" onClick={() => menuClick(Routes.events)}>Events</DropItem>,
            key: 'pub-events',
        },
        {
            label: <DropItem key="register" onClick={() => menuClick(null, AuthPopup.register)}>Register</DropItem>,
            key: 'pub-register',
        },
        {
            label: <DropItem key="login" onClick={() => menuClick(null, AuthPopup.login)}>Login</DropItem>,
            key: 'pub-login',
        },
    ], []);

    const items = useMemo(() => {
        if (!isAuthenticated) {
            return publicMenu;
        }
        return menu
    }, [isAuthenticated])

    return (
        <HeaderLayout>
            <HeaderMain className="d-flex align-items-center justify-content-between desktop">
                <div className="d-flex flex-row align-items-center"><ImageLogo
                    src="/images/login/gitkitlogo.png"
                    className="responsive ml-3 py-1 px-2 pointer"
                    alt="logo"
                    onClick={() => { navigateToTop(); menuClick(Routes.home); }}
                />
                    {false && <Form className="col-12 mb-0 d-flex align-items-center">
                        <Form.Item className="col-12 mb-0">
                            <FormSelectionField
                                control={control}
                                name="CountryId"
                                placeholder="Select country"
                                errors={errors?.CountryId}
                                defaultValue=""
                                options={countryList}
                            />
                        </Form.Item>
                    </Form>}
                    {countryList.length && <CountryDropDown list={countryList} />}
                </div>
                <div className="d-flex align-items-center justify-content-center">
                    <MenuTag className="link" onClick={() => menuClick(Routes.events)}>
                        Events
                    </MenuTag>
                    {!isAuthenticated && <>
                        <MenuTag className="link" onClick={() => menuClick(null, AuthPopup.login)}>
                            Login
                        </MenuTag>
                        <MenuTag className="link" onClick={() => menuClick(null, AuthPopup.register)}>
                            <RoundedCornerButton color={theme.colors.danger}>Register</RoundedCornerButton>
                        </MenuTag>
                    </>}
                    {isAuthenticated && <>
                        <MenuTag className="link" onClick={() => menuClick(Routes.addEvent)}>
                            Add Events
                        </MenuTag>
                        <MenuTag className="link" onClick={() => menuClick(Routes.profile)}>
                            Profile
                        </MenuTag>
                        <MenuTag className="link" onClick={() => logoutProfile()}>
                            Logout
                        </MenuTag>
                        {profile?.ProfilePicture && <img
                    src={profile?.ProfilePicture}
                    className="responsive ml-3 py-1 px-2 pointer"
                    height={40}
                    alt=""
                />}
                        <div className="d-flex align-items-center">
                            <p className="my-0">{CommonUtility.getInitials(profile?.FullName || profile?.Email)}</p>
                        </div>
                    </>}
                </div>
            </HeaderMain>
            <div className="d-flex align-items-center justify-content-between sidebar">
                <ImageSider
                    src="/images/login/gitkitlogo.png"
                    alt="logo"
                    className="my-2 pointer"
                    onClick={() => menuClick(Routes.home)}
                />
                <Dropdown
                    placement="bottomRight"
                    menu={{ items }}
                    trigger={['click']}
                    overlayClassName="dropMenu"
                    destroyPopupOnHide
                >
                    <List size={28} weight="fill" color={theme.colors.white} className="pointer" />
                </Dropdown>
            </div>
        </HeaderLayout>
    )
}