import { yupResolver } from '@hookform/resolvers/yup';
import { Form, Table, Tag } from 'antd';
import { DangerText, FlexRowBetween, PushNotification } from 'components';
import { PopUpModal } from 'components/PopUpModal';
import dayjs from 'dayjs';
import { FormCheckBoxField, FormDateTimeField, FormDivider, FormNormalField, FormTextAreaFormField, FormTextFormField, OutlinedButton, PrimaryButton } from 'elements';
import { NotePencil, Plus, Trash } from 'phosphor-react';
import React, { useEffect, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form';
import { CommonUtility, EventsService, NotificationStatus, NotificationText, theme, TicketUtility } from 'utility';
import * as yup from 'yup'
// import { CodeModel } from './Code';
import { Faqs } from './Faqs';
import { TermsConditions } from './T&C';
import { TeamMember } from './TeamMember';

const EventSchema = yup.object().shape({
    TicketType: yup.string().required('*Ticket Type is required').nullable(),
    TicketQty: yup.number().min(0, "*Less then 0 is inValid ").typeError("*Ticket Qty is required").required('*Ticket Qty is required'),
    TicketPrice: yup.number().min(0, "*Less then 0 is inValid ").typeError("*Ticket Price is required").required('*Ticket Price is required'),
    TicketDiscount: yup.number().min(0, "*Less then 0 is inValid ").max(100, "*Must be less then 100").typeError(""),
    TicketDescription: yup.string().nullable(),
    SellingStartEndDateTime: yup.array().nullable(true).required('*Selling Start & End Date/Time is required'),
    SeatNo: yup.string().nullable(),
    IsSeatOption: yup.boolean().default(false).oneOf([true, false], "You must select Event Type"),
    IsFreeEvent: yup.boolean().default(false).oneOf([true, false], "You must select Event Type"),
    Seats: yup.array().of(yup.object().shape({
        RowName: yup.string(),
        SeatId: yup.string(),
        Status: yup.string(),
        SeatNo: yup.string(),

    })),
    RowSeat: yup.array().of(yup.object().shape({
        id: yup.string(),
        row: yup.string().required("Row is Required"),
        seat: yup.string().required("*Seat Number is Required"),
    })),
})

export const TicketTable = ({ setTicket, IsTicket, eventStartDate, eventStartTime, data, submitTerms, submitFaqs, eventData, terms, faqs }) => {
    const [ticketRateModal, setTicketRateModal] = useState(false);
    const [ticketRemoveModal, setTicketRemoveModal] = useState(false);
    const [ticketData, setTicketData] = useState([])
    const [loading, setLoading] = useState(false)
    const [editMode, setEditMode] = useState(false)
    const [editPreviousData, setEditPreviousData] = useState({})
    const [seatOption, setSeatOption] = useState(false)
    const [error, setError] = useState("")
    const {
        control,
        handleSubmit, reset, watch, setValue,
        formState: { errors },
    } = useForm({
        context: { seatOption },
        resolver: yupResolver(EventSchema),
        defaultValues: {
            TicketType: "",
            TicketDescription: "",
            TicketPrice: 0,
            TicketQty: 0,
            SellingStartEndDateTime: "",
            SeatNo: "",
            RowSeat: [],
        },
        shouldUnregister: true,
    })
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'RowSeat',
    });

    const IsSeatOption = watch("IsSeatOption")
    const freeEvent = watch('IsFreeEvent')

    useEffect(() => {
        setTicketData(data)
    }, [data])

    useEffect(() => {
        setSeatOption(!!IsSeatOption)
    }, [IsSeatOption])

    useEffect(() => {
        if (!editMode) {
            setValue("TicketDiscount", 0)
            const start = dayjs()
            const end = dayjs(dayjs(eventStartDate).format("YYYY-MM-DD") + ' ' + dayjs(eventStartTime).format("HH:mm:ss")).subtract(1, 'hour')
            setValue("SellingStartEndDateTime", [start, end])
        }
    }, [ticketRateModal, editMode,eventStartDate])

    useEffect(() => {
        if (!editMode && seatOption && ticketRateModal) {
            addNew()
        }
    }, [seatOption])

    useEffect(() => {
        if (!editMode) {
            setEditPreviousData({})
        }
    }, [editMode])

    const addNew = () => {
        append({ id: '', row: '', seat: '' })
    }

    const price = watch('TicketPrice')
    const discount = watch('TicketDiscount')
    const columns = [
        {
            title: 'Ticket Type',
            dataIndex: 'TicketType',
            key: 'TicketType',
            render: (text) => <a>{text}</a>,
        },
        {
            title: 'Qty',
            dataIndex: 'TicketQty',
            key: 'TicketQty',
        },
        {
            title: 'Discount',
            dataIndex: 'TicketDiscount',
            key: 'TicketDiscount',
        },
        {
            title: 'Price',
            dataIndex: 'TicketPrice',
            key: 'TicketPrice',
        },
        {
            title: 'Discount Price',
            dataIndex: 'TicketPrice',
            key: 'TicketPrice',
            render: (row, data) => {
                return (<>{CommonUtility.discountedValue(data.TicketPrice, data.TicketDiscount)} </>)
            },
        },
        // {
        //     title: 'Seat No / Quantity',
        //     dataIndex: 'SeatNo',
        //     align: "center",
        //     key: 'SeatNo',
        //     render: (row, data) => {
        //         return (<>{row || data?.TicketQty} </>)
        //     },
        // },
        {
            title: 'Action',
            dataIndex: 'TicketType',
            align: "center",
            key: 'TicketType',
            render: (row, data) => <DropDownEditDelete row={row} data={data} />,
        },
    ];

    const DropDownEditDelete = ({ row, data }) => {
        const onClick = (key) => {
            if (key === "edit") {
                editTicket(row, data)
                if (data.IsSeatOption || data.Seats.length) {
                    setSeatOption(true)
                }
            } else {
                openRemoveTicketModal(data)
            }
        }
        return (
            <div>
                <NotePencil size={22} className="mx-2 pointer" onClick={() => onClick("edit")} />
                <Trash size={22} className="mx-2 pointer" onClick={() => onClick("remove")} />
            </div>
        )
    }

    const openTicketModal = () => {
        setTicketRateModal(true)
        setEditMode(false)
        remove()
        reset({
            TicketType: "",
            TicketDescription: "",
            SellingStartEndDateTime: "",
            TicketQty: "",
            TicketPrice: "",
            TicketDiscount: "",
            SeatNo: "",
            RowSeat: "",
        })
    }

    const editTicket = (typeId, data) => {
        const obj = ticketData.find(item => (item?.EventTicketId || item?.TicketType) === (data?.EventTicketId || typeId))
        reset({ ...obj, SellingStartEndDateTime: [dayjs(obj.SellingStartDate), dayjs(obj.SellingEndDate)] })
        setEditPreviousData({ ...obj, SellingStartEndDateTime: [dayjs(obj.SellingStartDate), dayjs(obj.SellingEndDate)] })
        setTicketRateModal(true)
        setEditMode(true)
        setTicketRateModal(true)
    }

    const submit = async (formData) => {
        setLoading(true)
        const startDate = (dayjs(formData.SellingStartEndDateTime[0]).format('YYYY-MM-DD') + " " + dayjs(formData.SellingStartEndDateTime[0]).format("HH:mm:ss"))
        const endDate = (dayjs(formData.SellingStartEndDateTime[1]).format('YYYY-MM-DD') + " " + dayjs(formData.SellingStartEndDateTime[1]).format("HH:mm:ss"))
        const submitedData = {
            ...formData,
            IsSeatOption,
            TicketPrice: Number(formData.TicketPrice),
            TicketDiscount: Number(formData.TicketDiscount),
            SellingStartDate: startDate,
            SellingEndDate: endDate,
            tempId: new Date().toISOString(),
        }
        delete submitedData.SellingStartEndDateTime
        const { seatNumber, error: suberror } = TicketUtility.RowToNumber(submitedData.RowSeat)
        if (suberror && seatOption && submitedData?.RowSeat?.length) {
            setError(suberror)
            return
        }
        submitedData.SeatNo = submitedData?.SeatNo ? `${submitedData?.SeatNo},${seatNumber.toString()}` : `${seatNumber.toString()}`;
        submitedData.SeatNo = TicketUtility.NumberToCommaSeprated(submitedData.SeatNo) + (editPreviousData?.Seats?.length ? (";" + editPreviousData.Seats.map(item => item.SeatNo).toString().replaceAll(",", ";")) : "")
        delete submitedData.RowSeat
        if (!submitedData.SeatNo) {
            delete submitedData.SeatNo
        }
        try {
            if (eventData.ProviderEventId) {
                const apiData = { ...submitedData }
                delete apiData.RowSeat
                delete apiData.Seats
                if (editPreviousData.EventTicketId) {
                    await EventsService.updateTicket({ ...editPreviousData, ...apiData, ProviderEventId: eventData.ProviderEventId, EventTicketId: editPreviousData.EventTicketId })
                } else {
                    delete apiData.EventTicketId
                    await EventsService.addTicket({ ...editPreviousData, ...apiData, ProviderEventId: eventData.ProviderEventId })
                }
            }
            if (!editMode) {
                setTicket([submitedData, ...ticketData])
                setTicketRateModal(false)
            } else {
                const index = ticketData.findIndex(item => item.TicketType === editPreviousData.TicketType)
                ticketData[index] = submitedData
                setTicket([...ticketData])
                setTicketRateModal(false)
            }
            setLoading(true)
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
            reset({})
            setError("")
            setEditPreviousData({})
        }
    }

    const removeTicket = async () => {
        const filteredTicket = ticketData.filter(item => (item?.EventTicketId || item?.tempId) !== ticketRemoveModal)
        const findObj = ticketData.find(item => (item?.EventTicketId || item?.tempId) === ticketRemoveModal)
        if (eventData?.ProviderEventId) {
            await EventsService.removeTicket(findObj.EventTicketId)
        }
        setTicketData([...filteredTicket])
        PushNotification(NotificationText.removed, NotificationStatus.success)
        setTicketRemoveModal(false)
    }

    const openRemoveTicketModal = (data) => {
        setTicketRemoveModal(data?.EventTicketId || data?.tempId)
    }

    const removeSeat = async (id) => {
        await EventsService.removeSeat(id)
        setEditPreviousData({
            ...editPreviousData,
            Seats: editPreviousData.Seats.filter(item => item.SeatId !== id),
        })
    }

    useEffect(() => {
        if (freeEvent) {
            setValue("TicketPrice", 0)
            setValue("TicketDiscount", 0)
        }
    }, [freeEvent])

    return (
        <div>
            <FlexRowBetween className="my-3">
                <label>Ticket Table</label>
                <div className="d-flex">
                    <PrimaryButton className="mr-2" onClick={openTicketModal} disabled={IsTicket}>Add Ticket Rate</PrimaryButton>
                    <TermsConditions submitTerms={submitTerms} eventData={eventData} terms={terms} />
                    <Faqs submitFaqs={submitFaqs} eventData={eventData} faqs={faqs} />
                    {false && <TeamMember submitFaqs={submitFaqs} eventData={eventData} faqs={faqs} disabled={!eventData.ProviderEventId} />}
                    {/* <CodeModel /> */}
                </div>
            </FlexRowBetween>
            <Table
                columns={columns}
                dataSource={ticketData}
                size="small"
            />
            <Form>
                <PopUpModal
                    title={editMode ? "Edit Ticket Rate" : "Add Ticket Rate"}
                    open={ticketRateModal}
                    onSubmit={handleSubmit(submit)}
                    onCancel={() => { setTicketRateModal(false); setEditMode(false) }}
                    loading={loading}>
                    <FormDivider />
                    <div className="row">
                        <Form.Item className="col-6">
                            <FormCheckBoxField
                                control={control}
                                label=""
                                name="IsFreeEvent"
                                errors={errors?.IsFreeEvent}
                            >
                                No Price Ticket
                            </FormCheckBoxField>
                        </Form.Item>
                        <Form.Item className="col-6">
                            <FormCheckBoxField
                                control={control}
                                label=""
                                name="IsSeatOption"
                                errors={errors?.IsSeatOption}
                                disabled={freeEvent}
                            >
                                Seat Option
                            </FormCheckBoxField>
                        </Form.Item>
                        <Form.Item className="col-6">
                            <FormTextFormField
                                control={control}
                                label="Ticket Type"
                                name="TicketType"
                                placeholder="Add Ticket Type"
                                errors={errors?.TicketType}
                                defaultValue=""
                            />
                        </Form.Item>
                        <Form.Item className="col-6">
                            <FormTextFormField
                                control={control}
                                label="Ticket Qty"
                                name="TicketQty"
                                placeholder="Add Ticket Qty"
                                errors={errors?.TicketQty}
                                defaultValue={null}
                                type="number"
                            />
                        </Form.Item>
                        <Form.Item className="col-6">
                            <FormTextFormField
                                control={control}
                                label="Ticket Price"
                                name="TicketPrice"
                                placeholder="Add Ticket Price"
                                errors={errors?.TicketPrice}
                                type="number"
                                defaultValue={null}
                            />
                        </Form.Item>
                        <Form.Item className="col-6">
                            <FormTextFormField
                                control={control}
                                label="Ticket Discount (%)"
                                name="TicketDiscount"
                                placeholder="0"
                                errors={errors?.TicketDiscount}
                                type="number"
                                defaultValue={null}
                            />
                        </Form.Item>
                        <Form.Item className="col-12">
                            <FormDateTimeField
                                control={control}
                                label="Selling Start & End Date/Time"
                                name="SellingStartEndDateTime"
                                errors={errors?.SellingStartEndDateTime}
                            />
                        </Form.Item>
                        <Form.Item className="col-12">{editPreviousData?.Seats?.filter(item => item.Status === 1).map((item) => {
                            return (
                                <Tag
                                    key={item.SeatId}
                                    closable
                                    style={{ userSelect: 'none', marginBottom: '5px' }}
                                    color={theme.colors.secondary}
                                    onClose={() => removeSeat(item.SeatId)}
                                >{item.SeatNo}
                                </Tag>
                            )
                        })}
                        </Form.Item>
                        <Form.Item className="col-12">
                            <FormTextAreaFormField
                                control={control}
                                label="Ticket Description"
                                name="TicketDescription"
                                errors={errors?.TicketDescription}
                                defaultValue=""
                                height={50}
                            />
                        </Form.Item>
                        <FormDivider className="mb-2" />
                        {seatOption && <Form.Item className="col-12">
                            <FlexRowBetween className="col-12 align-items-center"><h3>Seat Details</h3>
                                <OutlinedButton onClick={addNew}>
                                    <Plus className="mr-3" />
                                    <span className="mx-2">Add Row</span>
                                </OutlinedButton>
                            </FlexRowBetween>
                            {fields.map((item, index) => {
                                return (<Form.Item className={`row-seat-${index}`}>
                                    <div className="row">
                                        <div className="col-3">
                                            <FormTextFormField
                                                control={control}
                                                label="Row"
                                                name={`RowSeat.${index}.row`}
                                                placeholder="A"
                                                errors={errors?.RowSeat?.[index]?.row}
                                            />
                                        </div>
                                        <div className="col-9">
                                            <FormTextFormField
                                                control={control}
                                                label="Seat"
                                                name={`RowSeat.${index}.seat`}
                                                placeholder="10-20,30"
                                                errors={errors?.RowSeat?.[index]?.seat}
                                            />
                                        </div>
                                    </div>
                                </Form.Item>)
                            })}
                            <FormTextAreaFormField
                                control={control}
                                label="Seat Number"
                                name="SeatNo"
                                placeholder="A10-A20,10,30"
                                errors={errors?.SeatNo}
                            />
                        </Form.Item>}
                        <Form.Item className="col-6">
                            <FormNormalField
                                label="Total Price"
                                value={CommonUtility.discountedValue(price, discount)}
                            />
                        </Form.Item>
                        <DangerText>{error && error}</DangerText>
                    </div>
                </PopUpModal>
                <PopUpModal
                    open={!!ticketRemoveModal}
                    onSubmit={removeTicket}
                    onCancel={() => setTicketRemoveModal(false)}
                >
                    <h2>Are You Sure To Want To Remove Ticket Section ?</h2>
                </PopUpModal>
            </Form>
        </div>
    )
}
