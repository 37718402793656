import styled from "styled-components"
import { ImageContainer } from "components";

const FooterLayout = styled.div`
position: static;
background-color: ${({ theme }) => theme.colors.primary};
color: ${({ theme }) => theme.colors.white} ;
text-align: center;
font-size:16px;
 `
const FooterRowSecond = styled.div`
display:flex;
justify-content: space-between !important;
column-gap:100px;
padding:30px 0px 0px;
flex-wrap:wrap;
.boldy{
    border-bottom:2px solid #ffff;
}
.text-start {
    text-align:start;
}
@media (max-width: 538px) {
    flex-direction: column;
  }
  @media (max-width: 770px) {
    column-gap:76px;
  }
 `
const LinkPage = styled.div`
row-gap: 1px !important;
font-weight: 400;
font-size: 13px;
text-align: initial;
@media (max-width: 700px) {
   text-align:start;
  }
`
export const FooterPage = ({ navigateToTop }) => {
    return (
        <FooterLayout className="">
            <FooterRowSecond className="container">
                <div className="col-12  row">
                <div className="mt-md-1 col-12 col-md-3 mb-4 mb-md-0 mt-sm-4 pointer" onClick={navigateToTop}>
                    <ImageContainer
                        src="/images/login/gitkitlogo.png"
                        className="img"
                    />
                </div>
                <div className="col-6 col-md-3 col-sm-4   text-start ">
                    <b className="boldy">Use Eventsy</b>
                    <LinkPage>
                        <p>How It Works</p>
                        <p>Pricing</p>
                        <p>Eventsy Boost</p>
                        <p>Eventsy Mobile App</p>
                        <p>FAQs</p>
                        <p>Sitemap</p>
                    </LinkPage>
                </div>
                <div className="col-6 col-md-3 col-sm-4  text-start "><b className="boldy">Plan Events</b>
                    <LinkPage>
                        <p>Sell Tickets Online</p>
                        <p>Event Planning</p>
                        <p>Sell Concert Tickets Online</p>
                        <p>Event Payment System</p>
                        <p>Virtual Events Platform</p>
                        <p>Post your event online</p>
                    </LinkPage>
                </div>
                <div className="col-6 col-md-3 col-sm-4  text-start "><b className="boldy">Connect With Us</b>
                    <LinkPage>
                        <p>Contact Support</p>
                        <p>Twitter</p>
                        <p>Facebook</p>
                        <p>LinkedIn</p>
                        <p>Instagram</p>
                    </LinkPage>
                </div>
                </div>
                <hr className="col-12" />
            </FooterRowSecond>
            <div className=" pb-2">Copyright 2017 <b>GITKIt</b>. All Rights Reserved</div>
        </FooterLayout>
    )
}