import { GetEventDetails, GetCategoryList, GetCountryList, GetEventPlaceList, GetEventProviderList, GetImageCategoryList, GetLanguageList, GetTimeZoneList, GetFAQList, GetTCList } from 'hooks'
import { AddEditEventForm, AddPlaceModal } from 'page-components'
import { useNavigate, useParams } from 'react-router-dom'
import { EventsService, NotificationStatus, NotificationText } from 'utility'
import { PushNotification } from 'components'
import { useAuth } from 'context'
import { useEffect, useState, useMemo } from 'react'

export const AddEditEventScreen = () => {
  const params = useParams()
  const { countryCode } = useAuth()
  const [addPlaceModal, setAddPlaceModal] = useState(false)
  const [placeSearch,setPlaceSearch] = useState("")
  const navigate = useNavigate()
  const { data, loading } = GetEventDetails(params?.id)
  const { data: countryList } = GetCountryList()
  const { data: languageList } = GetLanguageList()
  const { data: categoryList } = GetCategoryList()
  const { data: placeList, refetch: refetchPlaceList,loading: placeLoading } = GetEventPlaceList(countryCode,placeSearch)
  const { data: imageCategoryList } = GetImageCategoryList()
  const { data: timeZoneList, refetch: refetchTimeZoneList } = GetTimeZoneList(countryCode)
  const { data: providerList, addProvider } = GetEventProviderList()
  const { data: tcList } = GetTCList()
  const { data: faqList } = GetFAQList()

  const onPlaceSearch = (data) => {
    setPlaceSearch(data)
  }

  useEffect(() => {
    refetchPlaceList()
    refetchTimeZoneList()
  }, [countryCode])

  const eventData = useMemo(() => {
    let returnData = {}
    if (params?.id) { returnData = { ...data } }
    return returnData
  }, [params?.id, data])

  const submitEvent = async (submitedData) => {
    const subdata = { ...submitedData }
    subdata.EventTicketRate = subdata.EventTicketRate.map(item => {
      const newItem = { ...item }
        delete newItem.tempId
        return newItem
    })
    if (subdata.ProviderEventId) {
      const apidata = { ...data, ...subdata }
      delete apidata.EventImages;
      delete apidata.TC;
      delete apidata.EventFAQ;
      await EventsService.addEvent({ ...apidata })
      PushNotification(NotificationText.eventUpdate, NotificationStatus.success)
      navigate(-1)
    } else {
      await EventsService.addEvent(subdata)
      PushNotification(NotificationText.eventAdd, NotificationStatus.success)
    }
  }
  const openPlaceModal = () => {
    setAddPlaceModal(true)
  }

  const closePlaceModal = async (data) => {
    if (data) {
      await EventsService.addPlace(data)
      refetchPlaceList()
      PushNotification(NotificationText.placeAdded, NotificationStatus.success)
    }
    setAddPlaceModal(false)
  }

  return (
    <div className="container">
      <AddEditEventForm
        submitEvent={submitEvent}
        openPlaceModal={openPlaceModal}
        data={eventData}
        loading={loading}
        countryList={countryList}
        languageList={languageList}
        categoryList={categoryList}
        placeList={placeList}
        placeLoading={placeLoading}
        imageCategoryList={imageCategoryList}
        timeZoneList={timeZoneList}
        providerList={providerList}
        defaultTcList={tcList}
        defaultFaqList={faqList}
        addProvider={addProvider}
        onPlaceSearch={onPlaceSearch}
      />
      <AddPlaceModal open={addPlaceModal} onClose={closePlaceModal} />
    </div>
  )
}